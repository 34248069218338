<template>
  <div class="row">
    <div class="col">
      <h1 class="h3 mb-1 ml-3 text-gray-800">Master Data {{ pageName }}</h1>
      <h2 class="h3 mb-0 text-gray-800"></h2>
    </div>
   
 
<div class="container-fluid mt-3">
  <div class="card shadow">
  <b-tabs class="card-body">

    
    <b-tab  class="card-body" >
    <template #title>
      <i class="bi bi-zoom-in text-info mr-2"></i>Data Perusahaan
    </template>
   
     
              <div class="card-body" >
                <h3 class="text-center">{{ perusahaan.nama_perusahaan.toUpperCase() }}</h3>
                <p class="text-center">{{ perusahaan.alamat }}</p>

                <div class="mt-4">
                  
                  <div class="card-header py-3 d-flex flex-row align-items-center justify-content-between">
              <h5 class="m-0 font-weight-bold text-primary">Detail Perusahaan</h5>
            </div>
                 
                  <div class="row">
    <div class="col-12 col-md-12 mb-2" v-for="field in companyFields" :key="field.key">
      <div class="row no-gutters">
        <!-- Label Column -->
        <div class="col-lg-6 border border-top-0 border-right-0 p-2 d-flex align-items-center">
          <strong class="ml-3">{{ field.label }}:</strong>
        </div>

        <!-- Value Column -->
        <div class="col-lg-6 border border-top-0 p-2 d-flex align-items-center">
          <span v-if="field.key === 'akta_pendirian'">
            <a :href="perusahaan[field.key]" target="_blank">Lihat Dokumen</a>
          </span>
          <span v-else>{{ perusahaan[field.key] }}</span>
        </div>
      </div>
    </div>
  </div>
        <div class="row no-gutters mt-4">  
       <div class="card-body">
                  <div class="card-header py-3 d-flex flex-row align-items-center justify-content-between">
              <h5 class="m-0 font-weight-bold text-primary">Pengurus</h5>
            </div>
                  <div v-for="(pengurus, index) in perusahaan.pengurus" :key="index" class=" col-12 col-md-12 mb-3 border p-3 rounded">
                    <h5>{{ pengurus.nama }}</h5>
                    <p><strong>Jabatan: </strong>{{ pengurus.jabatan }}</p>
                    <p><strong>Nama: </strong>{{ pengurus.nama_pengurus }}</p>
                    <!-- <p><strong>Telepon: </strong>{{ pengurus.kontak }}</p> -->
                  </div>
                
                </div>
                </div>

              
              </div>
              
              </div>
    
    
   </b-tab>
   <b-tab active>
      <template #title>
        <i class="bi bi-pencil text-info mr-2"></i> Ubah Data
      </template>
      <div class="card-body">
          <div v-if="!perusahaan.alamat">
            <h5>Formulir Data Perusahaan</h5>
          </div>

          <div v-if="errors.length">
            <div class="alert alert-danger alert-dismissible fade show" role="alert">
              <ul>
                <li v-for="error in errors" :key="error">{{ error }}</li>
              </ul>
              <button type="button" class="btn-close" @click="clearErrors"></button>
            </div>
          </div>


          

          <form @submit.prevent="submitForm" id="form_perusahaan">
      
  <!-- NIB Field -->
  <div class="form-group">
    <label for="nib">Nomor Induk Berusaha (NIB) <small style="color: red;">*</small></label>
    <input
      type="text"
      class="form-control"
      id="nib"
      name="nib"
      placeholder="Masukan Nomor Induk Berusaha (NIB)"
      v-model="perusahaan.nib"
      required
      readonly
    />
  </div>

  <!-- Nama Perusahaan Field -->
  <div class="form-group">
    <label for="name">Nama Perusahaan <small style="color: red;">*</small></label>
    <input
      type="text"
      class="form-control"
      id="name"
      name="name"
      placeholder="Masukan Nama Perusahaan"
      v-model="perusahaan.nama_perusahaan"
      required
      readonly
    />
  </div>

  <!-- Alamat Field -->
  <div class="form-group">
    <label for="alamat">Alamat Perusahaan <small style="color: red;">*</small></label>
    <textarea
      class="form-control"
      id="alamat"
      name="alamat"
      placeholder="Masukan Alamat Perusahaan"
      v-model="perusahaan.alamat"
      required
    ></textarea>
  </div>

  <!-- Status Penanaman Modal Field -->
  <div class="form-group">
    <label for="status_penanaman_modal">Status Penanaman Modal <small style="color: red;">*</small></label>
    <select
      class="form-control"
      id="penanaman_modal"
      name="penanaman_modal"
      v-model="perusahaan.status_penanaman_modal"
      required
    >
      <option value="">Pilih Status</option>
      <option value="PMA ( Penanaman Modal Asing )">PMA (Penanaman Modal Asing)</option>
      <option value="PMDN ( Penanaman Modal Dalam Negri )">PMDN (Penanaman Modal Dalam Negeri)</option>
    </select>
  </div>

  <!-- Skala Usaha Field -->
  <div class="form-group">
    <label for="skala_usaha">Skala Usaha <small style="color: red;">*</small></label>
    <select
      v-model="perusahaan.skala_usaha_id"
      class="form-control"
      id="skala_usaha"
      required
    >
      <option disabled value="">Pilih Skala Usaha</option>
      <option v-for="skala in skalausaha" :key="skala.skala_usaha_id" :value="skala.skala_usaha_id">
        {{ skala.skala }}
      </option>
    </select>
  </div>

  <!-- Tanggal Terbit NIB Field -->
  <div class="form-group">
    <label for="tanggal_terbit_nib">Tanggal Terbit NIB <small style="color: red;">*</small></label>
    <input
      type="date"
      class="form-control"
      id="tanggal_terbit_nib"
      name="tanggal_terbit_nib"
      v-model="perusahaan.tanggal_terbit_nib"
      required
    />
  </div>

  <!-- Akta Pendirian (File Upload) -->
  <fieldset>
    <div class="form-group">
      <label for="izinFile">Upload Akta Pendirian (PDF) <small style="color: red;">*</small></label>
      <b-form-file
        ref="izinFile"
        id="izinFile"
        name="akta_pendirian"
        placeholder="Maksimal 1MB"
        accept=".pdf"
        @change="handleFileUpload('izin', $event)"
      ></b-form-file>

      <!-- Upload Progress -->
      <div class="progress mt-3" v-if="uploadProgress.izin">
        <div
          class="progress-bar progress-bar-striped progress-bar-animated"
          role="progressbar"
          :style="{ width: uploadProgress.izin + '%' }"
        ></div>
      </div>

      <!-- File Details -->
      <div v-if="uploadedFiles.izinFile" class="alert alert-success mt-3">
        <button type="button" class="btn float-right" @click="removeFile('izin')">
          <i class="bi bi-trash-fill text-danger" style="font-size: 1.5rem;"></i>
        </button>
        Nama File: <span>{{ uploadedFiles.izinFile.name }}</span> <br />
        Ukuran File: <span>{{ (uploadedFiles.izinFile.size / 1024).toFixed(2) }} KB</span>
      </div>

      <!-- View Document -->
      <div v-if="perusahaan.akta_pendirian" class="alert alert-success mt-3">
        <a
          :href="`${perusahaan.akta_pendirian}`"
          target="_blank"
          style="text-decoration: none; color: inherit;"
        >
          <span>Lihat Dokumen</span>
        </a>
      </div>
    </div>
  </fieldset>

<!-- Susunan Pengurus Section -->
<fieldset>
  <div class="form-group">
    <label>Susunan Pengurus Sesuai Akta Pendirian <small style="color: red;">*</small></label>
    <div v-if="pengurusTemp.length === 0" style="color: red;">
      Setidaknya satu jabatan dan satu nama pengurus harus diisi.
    </div>

    <table class="table table-striped">
      <thead>
        <tr>
          <td>
            <div class="form-group">
  <div v-if="!isFreeText" class="input-group">
    <select
      v-model="newPengurus.jabatan_id"
      class="form-control"
      id="jabatanSelect"
      @change="handleJabatanChange"
    >
      <option disabled value="">Pilih Jabatan</option>
      <option
        v-for="jabatan in jabatans"
        :key="jabatan.jabatan_id"
        :value="jabatan.jabatan_id"
        :disabled="isJabatanDisabled(jabatan.jabatan_id)"
      >
        {{ jabatan.jabatan }}
      </option>
      <option value="Lainnya">Lainnya</option>
    </select>
  </div>

  <div v-else class="input-group">
    <input
      type="text"
      v-model="newPengurus.jabatan"
      class="form-control"
      placeholder="Jabatan lainnya"
      @blur="switchBackToSelect"
    />
  </div>

  <small v-if="jabatanValidation" style="color: red;">*Jabatan harus diisi*</small>
  <small v-if="jabatanExists" style="color: red;">*Data tidak bisa dipilih karena sudah ada*</small>
</div>

          </td>

          <td>
            <div class="form-group">
              <input
                v-model="newPengurus.nama_pengurus"
                type="text"
                class="form-control"
                id="nama_pengurus"
                placeholder="Nama Pengurus"
              />
              <small v-if="namaPengurusValidation" style="color: red;">*Nama pengurus harus diisi*</small>
            </div>
          </td>

          <td>
            <button type="button" class="btn" @click="tambahPengurus">
              <i class="bi bi-plus-square text-success" style="font-size: larger;"></i>
            </button>
          </td>
        </tr>
      </thead>

      <tbody>
        <tr v-for="(pengurus, index) in pengurusTemp" :key="index">
          <td>{{ pengurus.jabatan }}</td>
          <td>{{ pengurus.nama_pengurus }}</td>
          <td>
            <button class="btn btn-sm" type="button" @click="confirmHapusPengurus(pengurus)">
        <i class="bi bi-trash3 text-danger" style="font-size: larger;"></i>
      </button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</fieldset>

  <!-- Loading Spinner -->
  <div v-if="loading" class="loading-overlay">
    <div class="spinner"></div>
    <p>Tunggu Sebentar ...</p>
  </div>

  <!-- Submit Button -->
  <button :disabled="loading" type="submit" class="btn btn-primary mt-3">SIMPAN</button>
</form>

        </div>
    </b-tab>



    




  </b-tabs>
</div>
</div>

   
  <!-- Loading Spinner -->
  <div v-if="loading" class="loading-overlay">
    <div class="spinner"></div>
    <p>Tunggu Sebentar ...</p>
  </div>
   
  </div>
</template>
<script>
import axios from 'axios';

export default {
  name: "PerusahaanComponent",
  data() {

    return {
      url:`${process.env.VUE_APP_API_BASE_URL}`,
      loading: false, // Add this state
      pageName: "Perusahaan",
      perusahaan: {
        
        status_penanaman_modal: '',
        nib: '',
        tanggal_terbit_nib: '',
        nama_perusahaan: '',
        alamat: '',
        skala_usaha_id: '',
        skala_usaha: '',
        jabatans:[],
        pengurus: [], // Array pengurus yang diambil dari API
        jabatan_id:''
      },
      companyFields: [
        { key: 'nib', label: 'NIB (Nomor Induk Berusaha)' },
        { key: 'nama_perusahaan', label: 'Nama Perusahaan' },
        { key: 'alamat', label: 'Alamat' },
        { key: 'status_penanaman_modal', label: 'Status Penanaman Modal' },
        { key: 'tanggal_terbit_nib', label: 'Tanggal Terbit NIB' },
        { key: 'skala_usaha', label: 'Skala Usaha' },
        { key: 'akta_pendirian', label: 'Akta Pendirian' }
      ],
      pengurusFields: [
        { key: 'jabatan', label: 'Jabatan' },
        { key: 'nama_pengurus', label: 'Nama Pengurus' }
      ],
     
     
      jabatans: [], // Jabatan dari API
      newPengurus: {
        jabatan_id: "",
        jabatan: "",
        nama_pengurus: ""
      },
      pengurusTemp: [], // Temp array to store new pengurus
      isFreeText: false,
      jabatanValidation: false,
      jabatanExists: false, // add this new data property
      namaPengurusValidation: false,
      uploadProgress: {
         
          izin: 0,
        },
        uploadedFiles: {
         
          izinFile: '',
        },
      errors: [],
    };
  },
 
  methods: {
   
    async fetchSkalaUsaha() {
      try {
        const response = await axios.get(`${process.env.VUE_APP_API_BASE_URL}/api/v1/skala-usahas`);
        this.skalausaha = response.data.data; 
      } catch (error) {
        console.error('Failed to fetch company types:', error);
        this.errorMessage = 'Failed to load company types';
      }
    },
    async fetchJabatan() {
      try {
        const response = await axios.get(`${process.env.VUE_APP_API_BASE_URL}/api/v1/jabatans`);
        this.jabatans = response.data.data; 
      } catch (error) {
        console.error('Failed to fetch company types:', error);
        this.errorMessage = 'Failed to load company types';
      }
    }, 
  
    async fetchData() {
      this.loading = true; // Set loading to true when submission starts
    try {
      const response = await axios.get(`${process.env.VUE_APP_API_BASE_URL}/api/v1/perusahaan`, {
        headers: {
          'Accept': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
        },
      });
     
      this.perusahaan = response.data.data;
     
       // Convert date from DD-MM-YYYY to YYYY-MM-DD for the input field
       if (this.perusahaan.tanggal_terbit_nib) {
        const [day, month, year] = this.perusahaan.tanggal_terbit_nib.split('-');
        this.perusahaan.tanggal_terbit_nib = `${year}-${month}-${day}`;
      }
     
    // Initialize pengurusTemp from perusahaan.pengurus
    this.pengurusTemp = [...this.perusahaan.pengurus];

    } catch (error) {
      console.error('Error fetching company data:', error.response ? error.response.data : error.message);
      this.errors.push('Failed to fetch company data.');
    }finally {
    this.loading = false; // Reset loading state after submission completes
  }
  },
  async submitForm() {
  console.log("submitForm method called");
  this.loading = true;
  try {
    // Format the date to dd-mm-yyyy
    const formatDate = (dateString) => {
      const date = new Date(dateString);
      const day = String(date.getDate()).padStart(2, '0');
      const month = String(date.getMonth() + 1).padStart(2, '0'); // Month is 0-indexed
      const year = date.getFullYear();
      return `${day}-${month}-${year}`;
    };

    const formData = new FormData();
    formData.append('_method', 'PUT');
    // Other form data population
    formData.append('alamat', this.perusahaan.alamat);
    formData.append('skala_usaha_id', this.perusahaan.skala_usaha_id);
    formData.append('tanggal_terbit_nib', formatDate(this.perusahaan.tanggal_terbit_nib));
    formData.append('status_penanaman_modal', this.perusahaan.status_penanaman_modal);
    formData.append('akta_pendirian', this.uploadedFiles.izinFile);
  // Append other fields to FormData, excluding 'pengurus'

 // Append pengurus fields to FormData
this.pengurusTemp.forEach((item, index) => {
  // Only append if pengurus_id or jabatan_id is present
  if (!item.pengurus_id || item.jabatan_id) {
    formData.append(`pengurus[${index}][pengurus_id]`, item.pengurus_id);
    
    // Only append jabatan_id if it is not empty
    if (item.jabatan_id) {
      formData.append(`pengurus[${index}][jabatan_id]`, item.jabatan_id);
    }

    formData.append(`pengurus[${index}][jabatan]`, item.jabatan);
    formData.append(`pengurus[${index}][nama_pengurus]`, item.nama_pengurus);
  } else {
    // If pengurus_id and jabatan_id are empty, skip appending this item
    console.log('Hiding pengurus (jabatan_id is empty):', item);
  }
});


    const response = await axios.post(`${this.url}/api/v1/update-perusahaan`, formData, {
      headers: {
        'Accept': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('token')}`,
        'Content-Type': 'multipart/form-data',
      },
    });

    console.log('Data updated successfully:', response.data);
    await this.fetchData();
    this.$bvToast.toast('Data Perusahaan berhasil diubah', {
      title: 'BERHASIL',
      variant: 'success',
      solid: true,
    });

    

  } catch (error) {
    console.error('Error updating company data:', error.response ? error.response.data : error.message);
  } finally {
    this.loading = false;
  }
},
//   this.loading = true;
//   try {
//     // Create a new FormData instance
//     const formData = new FormData();

//     // Add 'PUT' method field to indicate an update
//     formData.append('_method', 'PUT');
//     // Add each field from `this.form` to `formData`
//     Object.keys(this.form).forEach((key) => {
//       if (key === 'pengurus') {
//         // Append `pengurus` as a JSON string if it's an array or object
//         formData.append(key, JSON.stringify(this.form[key].filter(item => !item.pengurus_id)));
//       } else {
//         formData.append(key, this.form[key]);
//       }
//     });


//     // If 'akta_pendirian' file is available in `uploadedFiles`, add it to `formData`
//       formData.append('akta_pendirian', this.uploadedFiles.izinFile);
    

//     // Send the request with `formData`
//     const response = await axios.post(`${this.url}/api/v1/update-perusahaan`, formData, {
//       headers: {
//         'Accept': 'application/json',
//         'Authorization': `Bearer ${localStorage.getItem('token')}`,
//         'Content-Type': 'multipart/form-data',
//       },
//     });

//     console.log('Data updated successfully:', response.data);
//     await this.fetchData(); 
//     this.$bvToast.toast('Data Perusahaan berhasil diubah', {
//       title: 'BERHASIL',
//       variant: 'success',
//       solid: true,
//     });

//   } catch (error) {
//     console.error('Error updating company data:', error.response ? error.response.data : error.message);

//     // Handle errors
//     if (error.response && error.response.data && error.response.data.errors) {
//       this.errors = Object.values(error.response.data.errors).flat();
//     } else {
//       this.errors.push('Failed to update company data.');
//     }
//   } finally {
//     this.loading = false;
//   }
// },
tambahPengurus() {
  // Validation for jabatan and nama pengurus
  if (!this.newPengurus.jabatan_id && !this.newPengurus.jabatan && !this.isFreeText) {
    this.jabatanValidation = true;
    return;
  }

  if (!this.newPengurus.nama_pengurus) {
    this.namaPengurusValidation = true;
    return;
  }

  // Add pengurus to temp array
  this.pengurusTemp.push({
    jabatan: this.isFreeText ? this.newPengurus.jabatan : this.jabatans.find(j => j.jabatan_id === this.newPengurus.jabatan_id).jabatan,
    nama_pengurus: this.newPengurus.nama_pengurus,
    jabatan_id: this.isFreeText ? '' : this.newPengurus.jabatan_id, // If it's free text, leave jabatan_id empty
  });

  // Reset input fields after adding
  this.newPengurus.jabatan_id = '';
  this.newPengurus.jabatan = '';
  this.newPengurus.nama_pengurus = '';

  this.jabatanValidation = false;
  this.namaPengurusValidation = false;
},
  // Method to log pengurus data
  logPengurusData() {
        if (this.pengurusTemp.length === 0) {
          console.log('No pengurus available.');
        } else {
          this.pengurusTemp.forEach((item, index) => {
            console.log(`Pengurus ${index} - ID: ${item.pengurus_id}, Type: ${typeof item.pengurus_id}`);
          });
        }
      },
      confirmHapusPengurus(pengurus) {
    // Show a confirmation dialog with the pengurus's name
    const isConfirmed = confirm(`Apakah Anda ingin menghapus pengurus ${pengurus.nama_pengurus}?`);
    
    // If confirmed, call the actual delete function
    if (isConfirmed) {
      this.hapusPengurus(pengurus.pengurus_id);
    }
  },
      hapusPengurus(pengurus_id) {
  // Log the id to ensure correct debugging information
  console.log(`Attempting to delete pengurus with pengurus_id: ${pengurus_id}`);

  // If id is undefined, handle the deletion locally
  if (typeof pengurus_id === 'undefined' || pengurus_id === 'undefined') {
    // Find the index of the pengurus with the undefined id
    const index = this.pengurusTemp.findIndex(pengurus => pengurus.pengurus_id === undefined);

    // Check if the pengurus with the undefined id exists locally
    if (index !== -1) {
      // Remove the pengurus at the found index
      this.pengurusTemp.splice(index, 1);

      // Log current pengurus data for debugging
      this.logPengurusData();

      console.log('Pengurus with undefined id has been removed locally.');
    } else {
      console.error('Pengurus with undefined id not found locally.');
    }
  } else {
    // Handle deletion when id is valid and provided
    this.deletePengurusFromServer(pengurus_id);
  }
},

async deletePengurusFromServer(pengurus_id) {
  // Find the index of the pengurus with the matching id
  const index = this.pengurusTemp.findIndex(pengurus => pengurus.pengurus_id === pengurus_id);
  
  // Check if the pengurus with the provided id exists locally
  if (index !== -1) {
    try {
      const token = localStorage.getItem('token');
      // Make the DELETE request to the server
      await axios.delete(`${process.env.VUE_APP_API_BASE_URL}/api/v1/pengurus-perusahaan/${pengurus_id}`, {
        headers: {
          'Accept': 'application/json',
          'Authorization': `Bearer ${token}`, 
          'Content-Type': 'multipart/form-data',
        },
      });

      // Remove the pengurus at the found index locally
      this.pengurusTemp.splice(index, 1);

      // Log current pengurus data for debugging
      this.logPengurusData();

      console.log(`Pengurus with pengurus_id ${pengurus_id} has been successfully deleted from the server and local data.`);
    } catch (error) {
      // Handle error if the delete request fails
      if (error.response && error.response.status === 404) {
        console.warn(`Pengurus with pengurus_id ${pengurus_id} not found on the server, removing locally.`);
        this.pengurusTemp.splice(index, 1); // Remove locally if not on the server
        this.logPengurusData();
      } else {
        console.error(`Failed to delete pengurus with pengurus_id ${pengurus_id}:`, error);
      }
    }
  } else {
    console.error(`Pengurus with pengurus_id ${pengurus_id} not found in local data.`);
  }
},
// hapusPengurus(index) {
//   // Remove pengurus from temp array
//   this.pengurusTemp.splice(index, 1);
// },
isJabatanDisabled(jabatan_id) {
    const existingJabatans = this.perusahaan.pengurus.map(p => p.jabatan);
    return (
      (jabatan_id === 1 && existingJabatans.includes("Direktur Utama")) ||
      (jabatan_id === 3 && existingJabatans.includes("Komisaris Utama"))
    );
  },

  handleJabatanChange() {
    // Validation for empty selection
    this.jabatanValidation = !this.newPengurus.jabatan_id;

    // Check if the selected jabatan_id corresponds to an already existing "Direktur Utama" or "Komisaris Utama"
    const selectedJabatan = this.jabatans.find(
      jabatan => jabatan.jabatan_id === this.newPengurus.jabatan_id
    )?.jabatan;
    
    if (
      (selectedJabatan === "Direktur Utama" && this.isJabatanDisabled(1)) ||
      (selectedJabatan === "Komisaris Utama" && this.isJabatanDisabled(3))
    ) {
      this.jabatanExists = true;
    } else {
      this.jabatanExists = false;
    }

    // Toggle free text input for "Lainnya"
    if (this.newPengurus.jabatan_id === 'Lainnya') {
      this.isFreeText = true;
      this.newPengurus.jabatan_id = '';
      this.newPengurus.jabatan = '';
    } else {
      this.isFreeText = false;
    }
  },

switchBackToSelect() {
  // Check if the text input is empty, if so, revert to the select dropdown
  if (this.newPengurus.jabatan.trim() === '') {
    this.isFreeText = false;
    this.newPengurus.jabatan_id = ''; // Clear jabatan_id when switching back to select
  }
},


handleFileUpload(type, event) {
            const file = event.target.files[0];
            if (file) {
              this.uploadedFiles[`${type}File`] = file;
            }
          },

          removeFile(type) {
            this.uploadedFiles[`${type}File`] = null;
            this.$refs[`${type}File`].reset();
          },
    
    clearErrors() {
      this.errors = [];
    },
    editData() {
      // Logic for editing data if necessary
    },
  },
  mounted() {
    this.fetchData();
    this.fetchSkalaUsaha();
    this.fetchJabatan();
  },
};
</script>

<style scoped>
/* Add your scoped styles here if needed */
.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.8);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 9999;
}
.bg-light {
  background-color: #f8f9fa !important;
}
.border {
  border-color: #dee2e6 !important;
}
.spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-left-color: #28a745;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.table-responsive {
  margin-top: 20px;
}
.btn-close {
  background-color: transparent;
  border: none;
}
.card-header {
  display: flex;
  justify-content: space-between;
}
.card-body h3 {
  font-weight: bold;
}
.card-body p {
  margin-top: 10px;
}
.form-group label {
  font-weight: bold;
}
.small-text {
  color: red;
}
.mt-3 {
  margin-top: 30px;
}
@mixin overflow-x($value) {
  overflow-x: $value !important;
}
.overflow-x-auto {
  @include overflow-x(auto);
}
.overflow-x-hidden {
  @include overflow-x(hidden);
}
.overflow-x-visible {
  @include overflow-x(visible);
}
.overflow-x-scroll {
  @include overflow-x(scroll);
}
</style>
