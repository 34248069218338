<template>
  <div class="dashboard">
    <SidebarComponent />
    <div class="argon-content">
      <TopbarComponent />
      <MainComponent>
        <AlertComponent />
        <ReportComponent />
        <!-- <ChartComponent /> -->
        <PieChartComponent />
     
      </MainComponent>
  
    </div>
  </div>
</template>

<script>
import TopbarComponent from '@/components/dashboard/TopbarComponent.vue';
import SidebarComponent from '@/components/dashboard/SidebarComponent.vue';
import MainComponent from '@/components/dashboard/MainComponent.vue';
// import ChartComponent from '@/components/dashboard/ChartComponent.vue';
import AlertComponent from '@/components/dashboard/AlertComponent.vue';
import ReportComponent from '@/components/dashboard/ReportComponent.vue';
import PieChartComponent from '@/components/dashboard/PieChartComponent.vue';

export default {
  name: 'DashboardView',
  components: {
    TopbarComponent,
    SidebarComponent,
    MainComponent,
    // ChartComponent,
    AlertComponent,
    ReportComponent,
    PieChartComponent,
   
   
  }
};
</script>

<style scoped>
.dashboard {
  display: flex;
  
  height: 100vh; /* Ensure full height of viewport */
  margin: 0;
  padding: 0;
}

.argon-content {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  height: 100%; /* Ensure content takes full height */
  margin: 0;
  padding: 0;
}

</style>
