<template>
    <div class="not-found">
      <div class="icon-container">
        <!-- <img src="https://via.placeholder.com/150x150?text=404" alt="404 Icon" /> -->
        <img :src="require('../assets/images/giphy.webp')" alt="404 Icon" />
      </div>
      <h1>Oops! Halaman Tidak Ditemukan</h1>
      <p>Sepertinya Anda tersesat. Halaman yang Anda cari tidak tersedia.</p>
      <router-link to="/" class="btn-home">Kembali ke Beranda</router-link>
    </div>
  </template>
  
  <script>
  export default {
    name: 'NotFoundView',
  };
  </script>
  
  <style scoped>
  .not-found {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background: linear-gradient(to bottom, #fff, #fff);
    color: #343a40;
    text-align: center;
    padding: 20px;
  }
  
  .icon-container img {
    width: 250px;
    height: 250px;
    margin-bottom: 20px;
    animation: float 3s ease-in-out infinite;
  }
  
  @keyframes float {
    0%, 100% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(-10px);
    }
  }
  
  h1 {
    font-size: 2.5rem;
    margin: 0;
    font-weight: bold;
    color: #495057;
  }
  
  p {
    font-size: 1.2rem;
    margin: 10px 0 20px;
    color: #6c757d;
  }
  
  .btn-home {
    display: inline-block;
    padding: 10px 20px;
    font-size: 1rem;
    font-weight: bold;
    text-decoration: none;
    color: #fff;
    background-color: #007bff;
    border-radius: 25px;
    transition: background-color 0.3s ease;
  }
  
  .btn-home:hover {
    background-color: #0056b3;
  }
  </style>
  