<template>
  <!-- Loading spinner -->
<div v-if="loading" class="loading-overlay">
      <div class="spinner"></div>
      <p>Tunggu Sebentar ...</p>
</div>
<div v-else>
    <div class="container-fluid mt-3">
      <div class="card shadow mb-4">
        <!-- Card Header - Dropdown -->
        <div class="card-header py-3 d-flex flex-row align-items-center justify-content-between">
          <h6 class="m-0 font-weight-bold text-primary">Laporan Kegiatan Usaha</h6>
          <button
      class="btn btn-primary mr-2 d-none d-lg-inline-block lg-show"
      type="button"
      @click="exportData"
    >
      Export <i class="bi-file-earmark mr-2"></i>
    </button>
        
        </div>
        <!-- Card Body -->
        <div class="card-body">
          <h6 class="m-0 font-weight-bold text-primary"></h6>
          <center>
           <img :src="require('../../assets/images/dkijkarta.png')"  alt="Logo" />
           <br><br>
           <h3><b>DINAS PARIWISATA DAN EKONOMI KREATIF</b></h3>

<h4>PEMERINTAH PROVINSI DAERAH KHUSUS IBUKOTA JAKARTA</h4>
<hr style=" border: none;
      height: 0.5px;
      background-color: #000; ">
           <h4 style="color: black;">DATA UNIT USAHA</h4>
           <br>
         </center>
        
            <div class="table-responsive">
                <table class="table table-bordered" width="100%" cellspacing="0">
                
                <tbody>
                    <tr>
                    <td width="50%">Nama Usaha</td>
                    <td width="50%" v-if="unitUsahaData">{{ unitUsahaData.nama_unit_usaha || 'Data not available' }}</td>
                    <td width="50%" v-else>Data not available</td>
                    </tr>
                    <tr>
                    <td>Alamat Usaha</td>
                    <td v-if="unitUsahaData">{{ unitUsahaData.alamat || 'Data not available' }}</td>
                    <td v-else>Data not available</td>
                    </tr>
                    <tr>
                    <td>Kelurahan</td>
                    <td v-if="unitUsahaData && unitUsahaData.kelurahan">{{ unitUsahaData.kelurahan.kelurahan || 'Data not available' }}</td>
                    <td v-else>Data not available</td>
                    </tr>
                    <tr>
                    <td>Kecamatan</td>
                    <td v-if="unitUsahaData && unitUsahaData.kecamatan">{{ unitUsahaData.kecamatan.kecamatan || 'Data not available' }}</td>
                    <td v-else>Data not available</td>
                    </tr>
                    <tr>
                    <td>Kotamadya/Kabupaten</td>
                    <td v-if="unitUsahaData && unitUsahaData.kabupaten">{{ unitUsahaData.kabupaten.kabupaten || 'Data not available' }}</td>
                    <td v-else>Data not available</td>
                    </tr>
                    <tr>
                    <td>NOPD</td>
                    <td v-if="unitUsahaData">{{ unitUsahaData.nopd || 'Data not available' }}</td>
                    <td v-else>Data not available</td>
                    </tr>
                    <tr>
                    <td>Luas Tanah</td>
                    <td v-if="unitUsahaData">{{ unitUsahaData.luas_tanah || 'Data not available' }} m<sup>2</sup></td>
                    <td v-else>Data not available</td>
                    </tr>
                    <tr>
                    <td>Luas Bangunan</td>
                    <td v-if="unitUsahaData">{{ unitUsahaData.luas_bangunan || 'Data not available' }} m<sup>2</sup></td>
                    <td v-else>Data not available</td>
                    </tr>
                    <tr>
                    <td>Nama Penanggung Jawab</td>
                    <td v-if="unitUsahaData">{{ unitUsahaData.penanggung_jawab.nama_pengurus || 'Data not available' }}</td>
                    <td v-else>Data not available</td>
                    </tr>
                    <tr>
                    <td>Jabatan Penanggung Jawab</td>
                    <td v-if="unitUsahaData">{{ unitUsahaData.penanggung_jawab.jabatan|| 'Data not available' }}</td>
                    <td v-else>Data not available</td>
                    </tr>
                    <tr>
                    <td>No. HP Penanggung Jawab</td>
                    <td v-if="unitUsahaData">{{ unitUsahaData.penanggung_jawab.kontak_pengurus || 'Data not available' }}</td>
                    <td v-else>Data not available</td>
                    </tr>
                    <tr>
                    <td>No. Telepon Kantor</td>
                    <td v-if="unitUsahaData">{{ unitUsahaData.telpon_kantor || 'Data not available' }}</td>
                    <td v-else>Data not available</td>
                    </tr>
                    <tr>
                    <td>Email</td>
                    <td v-if="unitUsahaData">{{ unitUsahaData.email || 'Data not available' }}</td>
                    <td v-else>Data not available</td>
                    </tr>
                    <tr>
                    <td>Website</td>
                    <td v-if="unitUsahaData">{{ unitUsahaData.website || 'Data not available' }}</td>
                    <td v-else>Data not available</td>
                    </tr>
                   
                    <tr>
                    <td>Instagram</td>
                    <td v-if="unitUsahaData">{{ unitUsahaData.instagram || 'Data not available' }}</td>
                    <td v-else>Data not available</td>
                    </tr>
                </tbody>
                </table>
            </div>

              <legend class="mt-3">Data Tenaga Kerja</legend>

              <div class="table-responsive">
                    <table class="table table-bordered" id="dataTable" width="100%" cellspacing="0">
                        <thead>
                            <tr>
                                <th>Jumlah Karyawan Pria TKI</th>
                                <th>Jumlah Karyawan Wanita TKI</th>
                                <th>Jumlah Karyawan Pria TKA</th>
                                <th>Jumlah Karyawan Wanita TKA</th>

                            </tr>
                        </thead>

                        <tbody>
                            <tr>
                                <td v-if="unitUsahaData">{{ unitUsahaData.tki_pria || 'Data not available'}}</td>
                                <td v-else>Data not available</td>
                                <td v-if="unitUsahaData">{{ unitUsahaData.tki_wanita || 'Data not available' }}</td>
                                <td v-else>Data not available</td>
                                <td v-if="unitUsahaData">{{ unitUsahaData.tka_pria || 'Data not available' }}</td>
                                <td v-else>Data not available</td>
                                <td v-if="unitUsahaData">{{ unitUsahaData.tka_wanita || 'Data not available'}}</td>
                                <td v-else>Data not available</td>
                            </tr>
                        </tbody>
                    </table>

                </div>
           
            <legend class="mt-3">Data Legalitas Usaha</legend>
          
            <div class="table-responsive">
    <table class="table table-bordered" id="dataTable" width="100%" cellspacing="0">
        <tbody>
            <tr>
                <td width="50%">Jenis Izin</td>
                <td width="50%" v-if="unitUsahaData">{{ unitUsahaData.jenis_izin || 'Data not available' }}</td>
                <td width="50%" v-else>Data not available</td>
            </tr>
            <tr>
                <td>Nomor Izin</td>
                <td v-if="unitUsahaData">{{ unitUsahaData.nomor_izin || 'Data not available' }}</td>
                <td v-else>Data not available</td>
            </tr>
            <tr>
                <td>Tanggal Terbit Izin</td>
                <td v-if="unitUsahaData">{{ unitUsahaData.tanggal_terbit_izin || 'Data not available' }}</td>
                <td v-else>Data not available</td>
            </tr>
            <tr>
                <td>Klasifikasi Resiko</td>
                <td v-if="unitUsahaData">{{ unitUsahaData.klasifikasi_resiko || 'Data not available' }}</td>
                <td v-else>Data not available</td>
            </tr>
            <tr>
                <td>Tipe Usaha</td>
                <td v-if="unitUsahaData">{{ unitUsahaData.tipe_usaha.tipe_usaha || 'Data not available' }}</td>
                <td v-else>Data not available</td>
            </tr>
            <tr>
                <td>Sertifikat Laik Sehat ( SLS )</td>
                <td v-if="unitUsahaData">{{ unitUsahaData.sertifikat_laik_sehat === 1 ? 'Ada' : 'Tidak Ada' }}</td>
                <td v-else>Data not available</td>
            </tr>
            <tr>
                <td>Masa Berlaku SLS </td>
                <td v-if="unitUsahaData">{{ unitUsahaData.masa_berlaku_laik_sehat || 'Data not available' }}</td>
                <td v-else>Data not available</td>
            </tr>
            <tr>
                <td>Sertifikat Standar Usaha ( SSU )</td>
                <td v-if="unitUsahaData">{{ unitUsahaData.sertifikat_standar_usaha === 1 ? 'Ada' : 'Tidak Ada' }}</td>
                <td v-else>Data not available</td>
            </tr>
            <tr>
                <td>Masa Berlaku SSU</td>
                <td v-if="unitUsahaData">{{ unitUsahaData.masa_berlaku_ssu || 'Data not available' }}</td>
                <td v-else>Data not available</td>
            </tr>
            <tr>
                <td>Penerbit SSU</td>
                <td v-if="unitUsahaData">{{ unitUsahaData.penerbit_ssu || 'Data not available' }}</td>
                <td v-else>Data not available</td>
            </tr>
        </tbody>
    </table>
</div>


          


<legend class="mt-3">Pemberkasan</legend>   
<div class="table-responsive">
    <table class="table table-bordered" id="dataTable" width="100%" cellspacing="0">
        <thead>
            <tr>
                <th>Jenis Berkas</th>
                <th>Action</th>
            </tr>
        </thead>
        <tbody>
            <tr>
                <td width="50%">Sertifikat Laik Sehat (SLS)</td>
                <td width="50%">
                    <a 
                        v-if="unitUsahaData && unitUsahaData.sls_file" 
                        class="text-info mx-2" 
                        :href="`${url}/storage/${unitUsahaData.sls_file}`" 
                        title="Download Berkas" 
                        target="_blank" 
                        data-bs-toggle="tooltip" 
                        data-bs-placement="top">
                        <i class="bi bi-file-earmark-arrow-down-fill text-success" style="font-size: 1.5rem;"></i>
                    </a>
                    <span v-else>Tidak Ada</span>
                </td>
            </tr>

            <tr>
                <td>Sertifikat Standar Usaha (SSU)</td>
                <td>
                    <a 
                        v-if="unitUsahaData && unitUsahaData.ssu_file" 
                        class="text-info mx-2" 
                        :href="`${url}/storage/${unitUsahaData.ssu_file}`" 
                        title="Download Berkas" 
                        target="_blank" 
                        data-bs-toggle="tooltip" 
                        data-bs-placement="top">
                        <i class="bi bi-file-earmark-arrow-down-fill text-success" style="font-size: 1.5rem;"></i>
                    </a>
                    <span v-else>Tidak Ada</span>
                </td>
            </tr>

            <tr>
                <td>Berkas Perizinan</td>
                <td>
                    <a 
                        v-if="unitUsahaData && unitUsahaData.file_perizinan" 
                        class="text-info mx-2" 
                        :href="`${url}/storage/${unitUsahaData.file_perizinan}`" 
                        title="Download Berkas" 
                        target="_blank" 
                        data-bs-toggle="tooltip" 
                        data-bs-placement="top">
                        <i class="bi bi-file-earmark-arrow-down-fill text-success" style="font-size: 1.5rem;"></i>
                    </a>
                    <span v-else>Tidak Ada</span>
                </td>
            </tr>

            
        </tbody>
    </table>
</div>


             <legend class="mt-3">Riwayat Laporan Kegiatan Usaha ( LKU )</legend>  
             <div class="table-responsive">
    <table class="table table-bordered" id="dataTable" width="100%" cellspacing="0">
        <thead>
            <tr>
                <th>Tahun Pelaporan</th>
                <th>Periode</th>
                <th>Tanggal Pelaporan</th>
                <th>Perbaikan</th>
                <th>Status</th>
                <th>Action</th>
            </tr>
        </thead>
        <tbody>
  <template v-if="sortedLkuData && sortedLkuData.length > 0">
    <tr v-for="lku in sortedLkuData" :key="lku.tlku_id">
      <td>{{ lku.tahun_pelaporan }}</td>
      <td>Semester {{ lku.semester }}</td>
      <td>{{ lku.tanggal_pelaporan }}</td>
      <td>
        <span v-if="lku.status_lku === 0" class="badge rounded-pill bg-primary">Draft</span>
        <span v-else-if="lku.status_lku === 1" class="badge rounded-pill bg-success">Disetujui</span>
        <span v-else-if="lku.status_lku === 2" class="badge rounded-pill bg-danger">Revisi</span>
      </td>
      <td>Perbaikan ke {{ lku.perbaikan }}</td>
      <td>
        <div class="text-info mx-2" @click="viewLku(lku.tlku_id)" title="Lihat Data">
          <i class="bi bi-zoom-in"></i> Lihat Data
        </div>
      </td>
    </tr>
  </template>
  <template v-else>
    <tr>
      <td colspan="6" class="text-center">Data tidak tersedia</td>
    </tr>
  </template>
</tbody>

    </table>
</div>

          </div>
         
      </div>
    </div>
</div>
</template>

<script>
import axios from 'axios'; // Ensure axios is imported

export default {
  name: "ViewUsahaComponent",

  data() {
    return {
      loading: false, // Add this state
      url:`${process.env.VUE_APP_API_BASE_URL}`,
      token: localStorage.getItem('token') || 'Token tidak ditemukan',
      unitUsahaData:{
        laporan_lku: [], // Default ke array kosong
      },
        errorMessage: '', // Initialize error message as an empty string
    };
  },
  
  async mounted() {
  this.loading = true; // Set loading to true when submission starts
  this.errorMessage = null; // Clear any previous error messages

  try {
    const token = localStorage.getItem('token');

    // Check if the token exists
    if (!token) {
      this.errorMessage = 'Authentication token is missing. Please log in again.';
      console.error(this.errorMessage);
      return;
    }

    const id = this.$route.query.id;

    // Check if ID is available
    if (!id) {
      this.errorMessage = 'ID is missing in the route query parameters.';
      console.error(this.errorMessage);
      return;
    }

    // API call
    const response = await axios.get(`${process.env.VUE_APP_API_BASE_URL}/api/v1/unit-usaha/${id}`, {
      headers: {
        'Accept': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
    });

    // Ensure response.data has the expected structure
    if (response.data && response.data.data) {
      this.unitUsahaData = response.data.data;
      console.log('Fetched data:', this.unitUsahaData);
    } else {
      this.errorMessage = 'Unexpected response structure from the API.';
      console.error(this.errorMessage, response.data);
    }

  } catch (error) {
    // Check if it's a network error or server error
    const errorMessage = error.response
      ? `API Error: ${error.response.data?.message || error.message}`
      : `Network Error: ${error.message}`;
      
    this.errorMessage = errorMessage;
    console.error('Error:', errorMessage);
  } finally {
    this.loading = false; // Reset loading state after submission completes
  }
},
computed: {
  sortedLkuData() {
    // Jika laporan_lku null atau bukan array, kembalikan array kosong
    const laporanLku = Array.isArray(this.unitUsahaData.lapor_lku)
      ? this.unitUsahaData.lapor_lku
      : [];
    
    // Lakukan sorting pada array
    return [...laporanLku].sort((a, b) => {
      // Sort by 'tahun_pelaporan' descending
      if (b.tahun_pelaporan !== a.tahun_pelaporan) {
        return b.tahun_pelaporan - a.tahun_pelaporan;
      }

      // Sort by 'semester' in ascending order (Semester 1 before Semester 2)
      const semesterOrder = [2, 1];
      const aIndex = semesterOrder.indexOf(a.semester);
      const bIndex = semesterOrder.indexOf(b.semester);

      if (aIndex !== bIndex) {
        return aIndex - bIndex;
      }

      // Sort by 'perbaikan' descending
      return b.perbaikan - a.perbaikan;
    });
  },
},

  methods: {
    async exportData() {
  try {
    const token = localStorage.getItem('token');
    if (!token) {
      this.errorMessage = 'Authentication token is missing. Please log in again.';
      console.error(this.errorMessage);
      return;
    }

    const id = this.$route.query.id;
    if (!id) {
      this.errorMessage = 'ID is missing in the route query parameters.';
      console.error(this.errorMessage);
      return;
    }

    const url = `${process.env.VUE_APP_API_BASE_URL}/api/v1/export/unit-usaha/${id}`;

    // Create a form and submit it to open the PDF in a new tab
    const form = document.createElement('form');
    form.method = 'GET'; // or 'POST', depending on your backend
    form.action = url;
    form.target = '_blank'; // Open in a new tab

    // Add the token as a hidden input field
    const inputToken = document.createElement('input');
    inputToken.type = 'hidden';
    inputToken.name = 'Authorization'; // Common name for Authorization headers in forms
    inputToken.value = `Bearer ${token}`; // Add token as Bearer token
    form.appendChild(inputToken);

    // Optional: Generate random code if needed for uniqueness
    this.randomCode = Array.from({ length: 16 }, () =>
      'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'.charAt(Math.floor(Math.random() * 62))
    ).join('');
    
    // Add the random code (if applicable) to the form
    const randomInput = document.createElement('input');
    randomInput.type = 'hidden';
    randomInput.name = this.randomCode; // Set the random code as the name
    randomInput.value = this.randomCode; // Set the random code as the value
    form.appendChild(randomInput);

    // Append the form to the document body
    document.body.appendChild(form);
    
    // Submit the form
    form.submit();

    // Optionally remove the form after submission
    form.remove();

    // Success message to the user
    this.successMessage = 'PDF opened in a new tab successfully!';
  } catch (apiError) {
    console.error('API request error:', apiError);
    this.errorMessage = 'Failed to export data. Please try again.';
  }
}
,

  viewLku(tlku_id) {
    
    // Redirect to the view page with the provided tlku_id
    this.$router.push({
      path: '/dashboard/unit-usaha/lku/view',
      query: { tlku_id: tlku_id }
    });
  },
},
}
</script>


<style scoped>
/* Add some basic styling if needed */
.alert {
  color: red;
}
/* Add your scoped styles here if needed */
.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.8);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 9999;
}

.spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-left-color: #28a745;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
