import Vue from 'vue'
import App from './App.vue'
import router from './router'
import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue'
import { mask } from 'vue-the-mask';
import './assets/css/main.css'



import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

import 'animate.css';
import WOW from 'wowjs';

new WOW.WOW().init();
Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons)
Vue.config.productionTip = false
Vue.directive('mask', mask);
new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
