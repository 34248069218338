<template>
  <!-- Conditionally render the alert component based on the value of `shouldShowAlert` -->
  <div v-if="shouldShowAlert" class="alert alert-info alert-dismissible fade show mb-4 border-left-primary shadow h-100 py-2" role="alert">
    <div class="card-body">
      <!-- Close Button -->
      <button type="button" class="close" data-dismiss="alert" aria-label="Close">
        <span aria-hidden="true">&times;</span>
      </button>
      <!-- Alert Content -->
      <div class="row no-gutters align-items-center">
        <!-- Icon Column -->
        <div class="col-xl-2 col-md-2 text-md-left">
          <b-icon icon="file-earmark-fill" class="text-dark mb-3" scale="2"></b-icon>
        </div>
        <!-- Text Content Column -->
        <div class="col-xl-6 col-md-7">
          <div class="h5 mb-0 font-weight-bold text-dark">Lengkapilah Data Perusahaan Anda</div>
          <div class="text-xs text-dark text-uppercase mt-1">
            Mohon lengkapi data usaha anda
          </div>
        </div>
        <!-- Button Column -->
        <div class="col-xl-3 col-md-3 text-center text-md-right">
          <a class="btn btn-danger py-2 mt-2 text-xs" href="/dashboard/perusahaan">Lengkapi Sekarang</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: "AlertComponent",
  data() {
    return {
      shouldShowAlert: false, // Initially hide the alert
    };
  },
  async created() {
    try {
      const response = await axios.get(`${process.env.VUE_APP_API_BASE_URL}/api/v1/perusahaan`, {
        headers: {
          'Accept': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
        }
      });

      // Correctly log the entire response data to verify
      //console.log('Full API Response:', JSON.stringify(response.data, null, 2));

      // Correctly access the nested data
      const companyData = response.data.data;
      const { nama_perusahaan, alamat, status_penanaman_modal } = companyData;

      //Log the correct fields
      console.log('Company Name:', nama_perusahaan);
      //console.log('Address:', alamat);
      //console.log('Responsible Person:', penanggung_jawab);
      //console.log('Job Title:', jabatan_penanggung_jawab);
      //console.log('Investment Status:', status_penanaman_modal);

      // Check if any of the required fields are missing (null or empty)
      if (!alamat || !status_penanaman_modal) {
        this.shouldShowAlert = true;
      } else {
        this.shouldShowAlert = false;
      }

      // Log the final value of `shouldShowAlert`
      console.log('shouldShowAlert:', this.shouldShowAlert);

    } catch (error) {
      console.error('Failed to fetch company data:', error);
    }
  }
}
</script>

<style>
.text-xs {
  font-size: 0.7rem;
}
.border-left-primary {
  border-left: 5px solid #007bff !important; /* Primary color */
}
@media (max-width: 576px) {
  .text-xs {
    font-size: 0.6rem;
  }
}
</style>
