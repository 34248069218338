<template>
    <div>
      <div class="container mt-5">
        <!-- About Start -->
        <div class="container px-lg-5">
          <div class="row g-5 align-items-center">
            <div class="col-lg-5">
              <img class="img-fluid wow zoomIn" data-wow-delay="0.5s" src="../../assets/images/about.svg">
            </div>
            <div class="col-lg-7 wow fadeInUp" data-wow-delay="0.1s">
              <div class="section-title position-relative mb-4 pb-4">
                <h1 class="mb-2"><strong>Keuntungan melaporkan kegiatan usahamu di sini </strong></h1>
              </div>
              <h5 class="mb-4">
                <ul> 
                  <li>Mendapatkan kepastian dan perlindungan hukum dalam usaha sesuai dengan lokasi yang sudah ditetapkan.</li>
                  <li>Mendapatkan pendampingan untuk pengembangan Usaha.</li>
                  <li>Mendapatkan kemudahan dalam pemberdayaan Kegiatan Usaha.</li>
                </ul>
              </h5>
              <b-button :href="'/register'" style="background-color: #EA6645; color: white; font-size: 22px;">
            <b-icon-arrow-right></b-icon-arrow-right> Lapor Sekarang
          </b-button>
            </div>
          </div>
        </div>
        <!-- About End -->
      </div>
    <br>
      <div class="container mt-5 px-5">
        <!-- Card Section Start -->
        <b-card-group deck>
          <!-- Card 1 -->
          <b-card
            title="Pemberdayaan"
            tag="article"
            style="max-width: 25rem;"
            class="mb-2"
          >
            <b-card-text style="text-align: justify;font-weight:400; ">
                Pertama, menciptakan suasana atau iklim yang memungkinkan potensi masyarakat berkembang (enablimg). Kedua memperkuat potensi atau daya yang dimiliki oleh ,asyarakat (empowering). Ketiga, memberdayakan mengandung pula arti melindungi (protecting).
            </b-card-text>
            <b-card-img :src="require('../../assets/images/pemberdayaan.png')" alt="Image" bottom></b-card-img>
             </b-card>
  
          <!-- Card 2 -->
          <b-card
            title="Kebersamaan"
            tag="article"
            style="max-width: 25rem;"
            class="mb-2"
          >
          <b-card-text style="text-align: justify;font-weight:400; ">
                Kebersamaan Usaha Mikro, Kecil, dan Menengah (UMKM) dan bank komersial merupakan salah satu dari sekian banyak bentuk simbiosis mutualisme dalam ekonomi. Kebersamaan tersebut bukan saja bermanfaat bagi keduanya, tetapi juga bagi masyarakat dan pemerintah.
            </b-card-text>
            <b-card-img :src="require('../../assets/images/kebersamaan.png')" alt="Image" bottom></b-card-img>
          </b-card>
  
          <!-- Card 3 -->
          <b-card
            title="Bermacam Usaha"
            
            tag="article"
            style="max-width: 25rem;"
            class="mb-2"
          >
          <b-card-text style="text-align: justify;font-weight:400; ">
                Usaha mikro, kecil dan usaha menengah (UMKM) yaitu usaha ekonomi produktif yang berdiri sendiri dan dilakukan oleh orang perseorangan atau badan usaha yang mampu memperluas lapangan kerja dan memberikan pelayanan ekonomi secara luas ke masyarak
            </b-card-text>
            <b-card-img :src="require('../../assets/images/usaha.png')" alt="Image" bottom></b-card-img>
          </b-card>
        </b-card-group>
        <!-- Card Section End -->
      </div>

    </div>
  </template>
  
  <script>
  export default {
    name: "ContentComponent"
  };
  </script>
  
  <style scoped>
  /* Your scoped styles here */
  </style>
  