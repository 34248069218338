<template>
  <div class="container">
    <div class="d-flex justify-content-center">
      <div class="col-lg-8">
        <b-card header-tag="header" footer-tag="footer" class="card shadow">
          <template #header>
            <h6 class="mb-0" ><b>TRACKING DOKUMEN - LAPORAN KEGIATAN USAHA</b></h6>
          </template>
          <center>
            <img :src="require('../assets/images/dkijkarta.png')" alt="Logo" />
          </center>
          <br>

          <!-- Jika berhasil diverifikasi -->
          <div v-if="successMessage" class="verification-animation">
            <span class="checkmark">&#10004;</span>
            <span class="verified-text"><b>Dokumen LKU Terverifikasi</b></span>
          </div>

          <!-- Jika gagal diverifikasi -->
          <div v-else class="verification-failed">
            <span class="crossmark">&#10008;</span>
            <span class="failed-text"><b>Dokumen Tidak Terverifikasi</b></span>
          </div>
          <br />

          <b-card-text>
            <!-- Tabel data jika berhasil -->
            <div v-if="successMessage">
              <table class="table table-bordered">
                <tbody>
                  <tr>
                    <th>Nomor LKU</th>
                    <td>{{ data.uniq_code }}</td>
                  </tr>
                  <tr>
                    <th>Nama Unit Usaha</th>
                    <td>{{ data.nama_unit_usaha }}</td>
                  </tr>
                  <tr>
                    <th>NOPD</th>
                    <td>{{ data.nopd }}</td>
                  </tr>
                  <tr>
                    <th>Tahun Pelaporan</th>
                    <td>{{ data.tahun_pelaporan }}</td>
                  </tr>
                  <tr>
                    <th>Tanggal Pelaporan</th>
                    <td>{{ data.tanggal_pelaporan }}</td>
                  </tr>
                  <tr>
                    <th>Semester</th>
                    <td>{{ data.semester }}</td>
                  </tr>
                </tbody>
              </table>
            </div>

            <!-- Pesan error jika gagal -->
            <div v-else>
              {{ errorMessage || 'Dokumen Tidak Terverifikasi. Silakan periksa kode atau coba lagi.' }}
            </div>
          </b-card-text>
        </b-card>
      </div>
    </div>
  </div>
</template>


<script>
import axios from 'axios';

export default {
  name: 'VerificationLkuView',
  data() {
    return {
      successMessage: '',
      errorMessage: '',
      data: {}, // Menyimpan data dari API
    };
  },
  mounted() {
    this.getVerificationData();
  },
  methods: {
    async getVerificationData() {
      const apiBaseUrl = process.env.VUE_APP_API_BASE_URL;
      const code = this.$route.query.code || ''; // Ambil kode dari parameter query URL
      const url = `${apiBaseUrl}/api/v1/verify`;

      try {
        const response = await axios.get(url, {
          params: { code }, // Kirim parameter "code"
          headers: {
            Accept: 'application/json',
          },
        });

        if (response.data.status) {
          this.successMessage = response.data.message || 'Verification successful!';
          this.data = response.data.data; // Simpan data ke dalam properti
        } else {
          this.errorMessage = response.data.message || 'Verification failed!';
        }
      } catch (error) {
        this.errorMessage = error.response?.data?.message || 'Something went wrong!';
      }
    },
  },
};
</script>

<style scoped>
.container {
  margin-top: 50px;
}

.alert {
  margin-top: 20px;
  text-align: center;
}

button {
  display: block;
  margin: 0 auto;
}

.verification-animation {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  font-size: 18px;
  color: green;
  animation: fadeIn 2s ease-in-out;
}

.verification-animation .checkmark {
  font-size: 24px;
  margin-right: 10px;
  animation: bounce 0.8s ease-in-out infinite alternate;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes bounce {
  from {
    transform: scale(1);
  }
  to {
    transform: scale(1.2);
  }
}
.verification-failed {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  font-size: 18px;
  color: red;
  animation: fadeIn 2s ease-in-out;
}

.verification-failed .crossmark {
  font-size: 24px;
  margin-right: 10px;
  animation: shake 0.8s ease-in-out infinite alternate;
}

@keyframes shake {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-5px);
  }
}

</style>
