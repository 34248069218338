<template>

<!-- Loading spinner -->
<div v-if="loading" class="loading-overlay">
  <div class="spinner"></div>
  <p>Tunggu Sebentar ...</p>
</div>

    <div v-else>
      <div class="container-fluid mt-3">
      <b-button variant="outline-primary" class="mb-3" @click="goBack"><i class="bi bi-arrow-left-circle-fill"></i> Kembali</b-button>
      <br>
      <div class="card shadow mb-4">
        <!-- Card Header - Dropdown -->
        <div class="card-header py-3 d-flex flex-row align-items-center justify-content-between">
          <h6 class="m-0 font-weight-bold text-primary"></h6>
          <button
      v-if="lkuData.status_lku === 1"
      class="btn btn-primary mr-2 d-none d-lg-inline-block lg-show"
      type="button"
      @click="exportData"
    >
      Export <i class="bi-file-earmark mr-2"></i>
    </button>
    <button v-else-if="lkuData.status_lku === 0"  class="btn btn-warning text-white mr-2 d-none d-lg-inline-block lg-show"
      type="button"
     ><b>DRAF</b></button>
      <button v-else-if="lkuData.status_lku === 2"  class="btn btn-danger mr-2 d-none d-lg-inline-block lg-show"
      type="button"
     ><b>REVISI</b></button>
      <button v-else  class="btn btn-primary mr-2 d-none d-lg-inline-block lg-show"
      type="button"
     >Tidak Diketahui</button>
  
        </div>
  
        <!-- Card Body -->
        <div class="card-body">

          <!-- Error Message -->
          <div v-if="errorMessage" class="alert alert-danger">
            {{ errorMessage }}
          </div>
          <center>
           <img :src="require('../../assets/images/dkijkarta.png')"  alt="Logo" />
           <br><br>
           <h3><b>DINAS PARIWISATA DAN EKONOMI KREATIF</b></h3>

<h4>PEMERINTAH PROVINSI DAERAH KHUSUS IBUKOTA JAKARTA</h4>
<hr style=" border: none;
      height: 0.5px;
      background-color: #000; ">
           <h4 style="color: black;">TANDA TERIMA LAPORAN KEGIATAN USAHA (LKU)</h4>
           <br>
         </center>

         <div>
    <p>Kepada Yth.</p>
    <p><strong>{{ lkuData.unit_usaha.nama_perusahaan }}</strong></p>
    <br>
    <p>
      Terima kasih telah menyampaikan Laporan Kegiatan Usaha (LKU) Sektor Pariwisata, 
      dengan keterangan sebagai berikut:
    </p>
  </div>
          <!-- LKU Data Display -->
            <div class="table-responsive">
                <table class="table table-bordered" width="100%" cellspacing="0">
                
                    <tbody>
                      <tr>
                        <td width="30%">Status</td>
                        <td width="70%"><span v-if="lkuData.status_lku === 1">Disetujui</span>
      <span v-else-if="lkuData.status_lku === 0">Draf</span>
      <span v-else-if="lkuData.status_lku === 2">Revisi</span>
      <span v-else>Tidak Diketahui</span></td>
                    </tr>
                    <tr>
                        <td width="30%">Nomer LKU</td>
                        <td width="70%">{{ lkuData.uniq_code }}</td>
                    </tr>
                    <tr>
                        <td width="30%">Nama Perusahaan</td>
                        <td width="70%">{{ lkuData.unit_usaha.nama_perusahaan }}</td>
                    </tr>
                    <tr>
                        <td width="30%">Nama Perusahaan</td>
                        <td width="70%">{{ lkuData.unit_usaha.nama_unit_usaha }}</td>
                    </tr>
                    <tr>
                        <td width="30%">Alamat</td>
                        <td width="70%">{{ lkuData.unit_usaha.alamat }}, {{ lkuData.unit_usaha.kelurahan }},{{ lkuData.unit_usaha.kecamatan }},{{ lkuData.unit_usaha.kabupaten }}</td>
                    </tr>
                    <tr>
                        <td>KBLI</td>
                        <td>{{ unitUsahaData.tipe_usaha.no_kbli }} </td>
                    </tr>
                    <tr>
                        <td>Nomor Registrasi 
                          Usaha Pariwisata</td>
                        <td>{{ unitUsahaData.uniq_code}} </td>
                    </tr>
                    <tr>
                        <td>Periode</td>
                        <td>Semester {{ lkuData.semester }}</td>
                    </tr>

                    <tr>
                        <td width="30%">Tahun Pelaporan</td>
                        <td width="70%">{{ lkuData.tahun_pelaporan }}</td>
                    </tr>
                  </tbody>
                </table>
            </div>
                   <br>
                   <div class="text-right">
    <!-- Menampilkan tanggal diterima -->
    <p>Tanggal Diterima</p>
    <p>{{ formatDate(lkuData.tanggal_pelaporan) }}</p>

    <br>
    <!-- Menampilkan nama admin -->
    <p class="mr-4"><B>Admin LKU</B></p>
  </div>

<br>
<center>
  <h3><b>LAPORAN KEGIATAN USAHA</b></h3>
</center> 
<br>
<!-- LKU Data Display -->
            <div class="table-responsive">
                <table class="table table-bordered" width="100%" cellspacing="0">
                
                    <tbody>
                    
                   
                    <tr>
                        <td>Periode</td>
                        <td>Semester {{ lkuData.semester }}</td>
                    </tr>

                    <tr>
                        <td width="30%">Tahun Pelaporan</td>
                        <td width="70%">{{ lkuData.tahun_pelaporan }}</td>
                    </tr>
                    <tr>
                        <td width="30%">Nomer LKU</td>
                        <td width="70%">{{ lkuData.uniq_code }}</td>
                    </tr>
                    <tr>
                        <td width="30%">Status</td>
                        <td width="70%"><span v-if="lkuData.status_lku === 1">Disetujui</span>
      <span v-else-if="lkuData.status_lku === 0">Draf</span>
      <span v-else-if="lkuData.status_lku === 2">Revisi</span>
      <span v-else>Tidak Diketahui</span></td>
                    </tr>
                  </tbody>
                </table>
            </div>


          <br>
          <h5><b>UNIT USAHA</b></h5>
          <div class="table-responsive">
  <table class="table table-bordered" width="100%" cellspacing="0">
    <tbody>
      <tr>
        <td width="30%">Nama Usaha</td>
        <td width="70%" v-if="lkuData.unit_usaha">{{ lkuData.unit_usaha.nama_unit_usaha || 'Data not available' }}</td>
        <td width="70%" v-else>Data not available</td>
      </tr>
      <tr>
        <td>Alamat Usaha</td>
        <td v-if="lkuData.unit_usaha">{{ lkuData.unit_usaha.alamat || 'Data not available' }}</td>
        <td v-else>Data not available</td>
      </tr>
      <tr>
        <td>Kelurahan</td>
        <td v-if="lkuData.unit_usaha">{{ lkuData.unit_usaha.kelurahan || 'Data not available' }}</td>
        <td v-else>Data not available</td>
      </tr>
      <tr>
        <td>Kecamatan</td>
        <td v-if="lkuData.unit_usaha">{{ lkuData.unit_usaha.kecamatan || 'Data not available' }}</td>
        <td v-else>Data not available</td>
      </tr>
      <tr>
        <td>Kotamadya/Kabupaten</td>
        <td v-if="lkuData.unit_usaha">{{ lkuData.unit_usaha.kabupaten || 'Data not available' }}</td>
        <td v-else>Data not available</td>
      </tr>
      <tr>
        <td>NOPD</td>
        <td v-if="lkuData.unit_usaha">{{ lkuData.unit_usaha.nopd || 'Data not available' }}</td>
        <td v-else>Data not available</td>
      </tr>
      <!-- <tr>
        <td>Luas Tanah</td>
        <td v-if="lkuData.unit_usaha">{{ lkuData.unit_usaha.luas_tanah || 'Data not available' }} m<sup>2</sup></td>
        <td v-else>Data not available</td>
      </tr>
      <tr>
        <td>Luas Bangunan</td>
        <td v-if="lkuData.unit_usaha">{{ lkuData.unit_usaha.luas_bangunan || 'Data not available' }} m<sup>2</sup></td>
        <td v-else>Data not available</td>
      </tr> -->
      <!-- <tr>
        <td>Nama Penanggung Jawab</td>
        <td v-if="lkuData.unit_usaha">{{ lkuData.unit_usaha.penanggung_jawab.nama_pengurus || 'Data not available' }}</td>
        <td v-else>Data not available</td>
      </tr>
      <tr>
        <td>Jabatan Penanggung Jawab</td>
        <td v-if="lkuData.unit_usaha">{{ lkuData.unit_usaha.penanggung_jawab.jabatan || 'Data not available' }}</td>
        <td v-else>Data not available</td>
      </tr>
      <tr>
        <td>No. HP Penanggung Jawab</td>
        <td v-if="lkuData.unit_usaha">{{ lkuData.unit_usaha.penanggung_jawab.kontak_pengurus || 'Data not available' }}</td>
        <td v-else>Data not available</td>
      </tr> -->
      <tr>
        <td>No. Telepon Kantor</td>
        <td v-if="lkuData.unit_usaha">{{ lkuData.unit_usaha.telepon_kantor || 'Data not available' }}</td>
        <td v-else>Data not available</td>
      </tr>
      <tr>
        <td>Email</td>
        <td v-if="lkuData.unit_usaha">{{ lkuData.unit_usaha.email || 'Data not available' }}</td>
        <td v-else>Data not available</td>
      </tr>
      <tr>
        <td>Website</td>
        <td v-if="lkuData.unit_usaha">{{ lkuData.unit_usaha.website || 'Data not available' }}</td>
        <td v-else>Data not available</td>
      </tr>
      <tr>
        <td>Skala Usaha</td>
        <td v-if="lkuData.unit_usaha">{{ lkuData.unit_usaha.skala_usaha || 'Data not available' }}</td>
        <td v-else>Data not available</td>
      </tr>
      <tr>
        <td>Instagram</td>
        <td v-if="lkuData.unit_usaha">{{ lkuData.unit_usaha.instagram || 'Data not available' }}</td>
        <td v-else>Data not available</td>
      </tr>
    </tbody>
  </table>
</div>

<br>
<h5><b>FASILITAS</b></h5>
<div class="table-responsive">
                <table class="table table-bordered" width="100%" cellspacing="0">
                
                    <tbody>
                      <tr>
                        <td width="30%">Fasilitas</td>
                        <td width="70%"> <span v-for="(fasilitas, index) in lkuData.fasilitas" :key="index">
    {{ fasilitas.nama_fasilitas }}<span v-if="index < lkuData.fasilitas.length - 1">, </span>
  </span>
      
                                 </td>
                    </tr>

                  </tbody>
                </table>
            </div>
<br>
  
  <div v-if="lkuData.punya_ruang_pertemuan === 1">
    <h5><b>RUANG PERTEMUAN</b></h5>
    <div class="table-responsive">
              <table class="table table-bordered" width="100%" cellspacing="0">
                <thead>
                  <tr>
                    <th width="30%">Jenis Ruang Pertemuan</th>
                    <th width="40%">Nama</th>
                    <th width="30%">Kapasitas</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(ruang) in lkuData.ruang_pertemuans" :key="ruang.ruang_pertemuan_id">
                    <td>{{ ruang.jenis_ruang_pertemuan }}</td>
                    <td>{{ ruang.nama_ruang_pertemuan }}</td>
                    <td>{{ ruang.kapasitas_ruang_pertemuan }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <br>
          <h5><b>TENAGA KERJA UNIT USAHA</b></h5>
          <div class="table-responsive">
                    <table class="table table-bordered" id="dataTable" width="100%" cellspacing="0">
                        <thead>
                            <tr>
                                <th>Jumlah Karyawan Pria TKI</th>
                                <th>Jumlah Karyawan Wanita TKI</th>
                                <th>Jumlah Karyawan Pria TKA</th>
                                <th>Jumlah Karyawan Wanita TKA</th>

                            </tr>
                        </thead>

                        <tbody>
                            <tr>
                                <td v-if="lkuData">{{ lkuData.unit_usaha.tki_pria || 'Data not available'}}</td>
                                <td v-else>Data not available</td>
                                <td v-if="lkuData">{{ lkuData.unit_usaha.tki_wanita || 'Data not available' }}</td>
                                <td v-else>Data not available</td>
                                <td v-if="lkuData">{{ lkuData.unit_usaha.tka_pria || 'Data not available' }}</td>
                                <td v-else>Data not available</td>
                                <td v-if="lkuData">{{ lkuData.unit_usaha.tka_wanita || 'Data not available'}}</td>
                                <td v-else>Data not available</td>
                            </tr>
                        </tbody>
                    </table>

                </div>
                <br>
                <h5><b>FORMULIR PERTANYAAN</b></h5>
           <div class="table-responsive">
                <table class="table table-bordered" width="100%" cellspacing="0">
                
                    <tbody>                                                
                                        
                  
 <!-- Iterate over the single_forms array -->
 <tr v-for="(form, index) in lkuData.single_forms" :key="index">
      <!-- Display the label -->
      <td width="30%">{{ form.label }}</td>
      
      <!-- Display the value based on the input type -->
      <td width="70%">
        <!-- Text or number inputs -->
        <span v-if="form.tipe_inputan === 'text' || form.tipe_inputan === 'number'">
          {{ form[form.slug] || 'N/A' }}
        </span>
        
        <!-- Checkbox inputs -->
        <span v-if="form.tipe_inputan === 'checkbox'">
          <!-- Gabungkan opsi dengan koma -->
          {{ form.options.join(', ') || 'N/A' }}
        </span>


        <!-- Select inputs -->
        <span v-if="form.tipe_inputan === 'select'">
          
          {{ 
             form[form.slug] || 'N/A'
          }}
      </span>  

        <span v-if="form.tipe_inputan === 'radio'">
          
            {{ 
               form[form.slug] || 'N/A'
            }}
        </span>  
        <span v-if="form.tipe_inputan === 'date'">
          
          {{ 
             form[form.slug] || 'N/A'
          }}
      </span> 
        <!-- Currency inputs -->
        <span v-if="form.tipe_inputan === 'uang ( Rp )'">
          Rp {{ formatRupiah(form[form.slug]) || 'N/A' }}
        </span>

        <!-- Percent inputs -->
        <span v-if="form.tipe_inputan === 'percent'">
          {{ form[form.slug] || '0' }}%
        </span>

        <!-- DateTime inputs -->
        <span v-if="form.tipe_inputan === 'time'">
          {{ form[form.slug]  || 'N/A' }}
        </span>
      </td>
    </tr>


                               
                 
                </tbody>
                </table>
            </div>

            
          <div v-for="(grup) in lkuData.grup_forms" :key="grup.grup_id">
  <h3>{{ grup.nama_grup }}</h3>
  <div class="table-responsive">
    <table class="table table-bordered" width="100%" cellspacing="0">
      <thead>
        <tr>
          <th width="50%">Label</th>
          <th width="50%">Nilai</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(form) in grup.forms" :key="form.slug">
          <td>{{ form.label }}</td>
          <td>
            <!-- Check if the input type is 'select' -->
            <template v-if="form.tipe_inputan.tipe_inputan === 'select'">
              {{ form.options.find(option => option.option_id == form[form.slug]).value || 'N/A' }}
            </template>

          <!-- Check if the input type is 'checkbox' -->
          <template v-else-if="form.tipe_inputan.tipe_inputan === 'checkbox'">
              <!-- Join selected option values into a single string -->
              {{ form[form.slug].map(selectedOption => form.options.find(option => option.option_id == (selectedOption.option_id || selectedOption.optioin_id)).value).join(', ') || 'N/A' }}
            </template>

            <!-- For other input types (e.g., text, number) -->
            <template v-else>
              {{ form[form.slug] }}
            </template>
          </td>
          
        </tr>
        <tr class="text-primary">
          <td>
            <b>Total</b>
          </td>
          <td>
            <b>{{ grup.total }}</b>
          </td>
        </tr>
        <tr class="text-primary">
            <td>
            <b>Rata Rata</b>
          </td>
          <td>
            <b>{{ grup.average }} </b>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>


<br>
<div 
  v-if="lkuData.status_lku === 2"
  class="mt-3 mb-3"
  style="border: 1px dashed red; color: red; padding: 8px;">
  <p><b>CATATAN :</b></p>
  {{ lkuData.alasan_revisi }}
</div>


        </div>

      </div>
      
    </div>
 
</div>
  </template>
  
  <script>
  import axios from 'axios';
  
  export default {
    name: "ViewLkuComponent",
    data() {
      return {
        errorMessage: null,
        lkuData: null,
        loading: false,
      };
    },
    
    async created() {
      this.fetchLkuData();
      this.fetchUnitUsahaData();
    },
    methods: {
      formatRupiah(value) {
      if (value || value === 0) {
        return Number(value).toLocaleString('id-ID');
      }
      return null;
    },
    formatDate(dateString) {
      if (!dateString) return 'Tanggal tidak tersedia';

      const months = [
        'Januari', 'Februari', 'Maret', 'April', 'Mei', 'Juni',
        'Juli', 'Agustus', 'September', 'Oktober', 'November', 'Desember'
      ];

      const [day, month, year] = dateString.split('-');
      return `${day} ${months[parseInt(month, 10) - 1]} ${year}`;
    },
      goBack() {
    this.$router.go(-1);  // This will navigate to the previous page.
  },
      async exportData() {
  try {
    const token = localStorage.getItem('token');
    // Check if the token exists
    if (!token) {
      this.errorMessage = 'Authentication token is missing. Please log in again.';
      console.error(this.errorMessage);
      return;
    }
    const tlku_id = this.$route.query.tlku_id;
    // Check if tlku_id is available
    if (!tlku_id) {
      this.errorMessage = 'ID is missing in the route query parameters.';
      console.error(this.errorMessage);
      return;
    }
    // Construct the URL for the PDF download
    const url = `${process.env.VUE_APP_API_BASE_URL}/api/v1/export/lapor-lku/${tlku_id}`;
    // Set Authorization header using Fetch API
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        'Accept': 'application/pdf',
        'Authorization': `Bearer ${token}`,
      },
    });

    // Check if the response is okay
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }

    // Create a Blob from the PDF response
    const blob = await response.blob();

    // Create a URL for the Blob and open it in a new tab
    const blobUrl = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = blobUrl;
    link.target = '_blank'; // Open in new tab
    link.setAttribute('download', `exported_data_${tlku_id}.pdf`); // Set the filename
    document.body.appendChild(link);
    link.click(); // Trigger the download
    link.remove(); // Remove the link after download

    // Optionally display a success message
    this.successMessage = 'Data exported successfully!';

  } catch (apiError) {
    console.error('API request error:', apiError);
    this.errorMessage = 'Failed to export data. Please try again.';
  }
},
async fetchLkuData() {
  this.loading = true; // Show loading indicator
  try {
    const token = localStorage.getItem("token");
    if (!token) {
      throw new Error("Authentication token is missing. Please log in again.");
    }

    // Fetch LKU Data
    const tlku_id = this.$route.query.tlku_id;
    if (!tlku_id) {
      throw new Error("tlku_id is missing in the route query parameters.");
    }

    const lkuResponse = await axios.get(
      `${process.env.VUE_APP_API_BASE_URL}/api/v1/lapor-lku/${tlku_id}`,
      {
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );

    if (lkuResponse.data && lkuResponse.data.data) {
      this.lkuData = lkuResponse.data.data; // Assign LKU data
    } else {
      throw new Error("LKU data is not available.");
    }

    // Fetch Unit Usaha Data
    const unitUsahaId = this.lkuData?.unit_usaha?.unit_usaha_id;
    if (!unitUsahaId) {
      throw new Error("Unit Usaha ID is missing in the LKU data.");
    }

    const unitUsahaResponse = await axios.get(
      `${process.env.VUE_APP_API_BASE_URL}/api/v1/unit-usaha/${unitUsahaId}`,
      {
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );

    if (unitUsahaResponse.data && unitUsahaResponse.data.data) {
      this.unitUsahaData = unitUsahaResponse.data.data; // Assign Unit Usaha data
    } else {
      throw new Error("Unit Usaha data is not available.");
    }
  } catch (error) {
    this.errorMessage =
      error.response?.data?.message ||
      error.message ||
      "Failed to fetch data. Please try again later.";
    console.error(error);
  } finally {
    this.loading = false; // Always hide loading indicator
    console.log("Loading is set to false");
  }
}

  
    },
  };
  </script>
  
  <style scoped>
  /* Add your scoped styles here if needed */
.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.8);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 9999;
}
.text-right {
  text-align: right;
}
.spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-left-color: #28a745;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

  /* Add any styling needed here */
  </style>
  