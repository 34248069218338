<template>
  <div class="home">
    <Navbar />
    <HeroHome />
    <HukumComponent />
    <TimelineComponent />
    <UsahaComponent />
    <ContentComponent />
    <FooterComponent />

  </div>
</template>

<script>

// @ is an alias to /src
import Navbar from '@/components/home/Navbar.vue'
import HeroHome from '@/components/home/HeroHome.vue'
import FooterComponent from '@/components/home/FooterComponent.vue'
import ContentComponent from '@/components/home/ContentComponent.vue'
import UsahaComponent from '@/components/home/UsahaComponent.vue'
import TimelineComponent from '@/components/home/TimelineComponent.vue'
import HukumComponent from '@/components/home/HukumComponent.vue'

export default {
  name: 'HomeView',
  components: {
    Navbar,
    HeroHome,
    FooterComponent,
    ContentComponent,
    UsahaComponent,
    TimelineComponent,
    HukumComponent
  }
}
</script>
