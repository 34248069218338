<template>
  <div class="row">

    
    <!-- Pie Chart for TKI Semester 1 -->
    <div class="col-xl-6 col-lg-5">
      <div class="card shadow mb-4">
        <div class="card-header py-3 d-flex flex-row align-items-center justify-content-between">
          
          <h6 class="m-0 text-primary">Data TKI Semester 1</h6>
        </div>
        <div class="card-body">
          <div class="chart-pie pt-4 pb-2">
            <canvas id="myPieChart1"></canvas>
          </div>
        </div>
      </div>
    </div>

    <!-- Pie Chart for TKI Semester 2 -->
    <div class="col-xl-6 col-lg-5">
      <div class="card shadow mb-4">
        <div class="card-header py-3 d-flex flex-row align-items-center justify-content-between">
          <h6 class="m-0 text-primary">Data TKI Semester 2</h6>
        </div>
        <div class="card-body">
          <div class="chart-pie pt-4 pb-2">
            <canvas id="myPieChart2"></canvas>
          </div>
        </div>
      </div>
    </div>

    <!-- Pie Chart for TKA Semester 1 -->
    <div class="col-xl-6 col-lg-5">
      <div class="card shadow mb-4">
        <div class="card-header py-3 d-flex flex-row align-items-center justify-content-between">
          <h6 class="m-0 text-primary">Data TKA Semester 1</h6>
        </div>
        <div class="card-body">
          <div class="chart-pie pt-4 pb-2">
            <canvas id="myPieChart3"></canvas>
          </div>
        </div>
      </div>
    </div>

    <!-- Pie Chart for TKA Semester 2 -->
    <div class="col-xl-6 col-lg-5">
      <div class="card shadow mb-4">
        <div class="card-header py-3 d-flex flex-row align-items-center justify-content-between">
          <h6 class="m-0 text-primary">Data TKA Semester 2</h6>
        </div>
        <div class="card-body">
          <div class="chart-pie pt-4 pb-2">
            <canvas id="myPieChart4"></canvas>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from 'axios';
import Chart from 'chart.js/auto';

export default {
  name: "PieChartComponent",
  data() {
    return {
      chartData: null
    };
  },
  mounted() {
    this.fetchChartData();
  },
  methods: {
    async fetchChartData() {
      try {
        // Fetch data from the API
        const response = await axios.get(`${process.env.VUE_APP_API_BASE_URL}/api/dashboard/karyawan?tahun=2024`, {
         
        headers: {
            'Accept': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('token')}`
          }
        });
        this.chartData = response.data.data;
        
        // Call method to initialize charts after data is fetched
        this.initializeCharts();
      } catch (error) {
        console.error('Error fetching chart data:', error);
      }
    },
    initializeCharts() {
      if (!this.chartData) return;

      // Get the contexts for the charts
      const ctx1 = document.getElementById('myPieChart1').getContext('2d');
      const ctx2 = document.getElementById('myPieChart2').getContext('2d');
      const ctx3 = document.getElementById('myPieChart3').getContext('2d');
      const ctx4 = document.getElementById('myPieChart4').getContext('2d');

      // Data for TKI Semester 1
      const dataTKISemester1 = {
        labels: ['Pria', 'Wanita'],
        datasets: [{
          label: 'Data TKI Semester 1',
          data: [this.chartData.semester_1.tki_pria, this.chartData.semester_1.tki_wanita],
          backgroundColor: ['#007bff', '#28a745'],
          hoverOffset: 4
        }]
      };

      // Data for TKI Semester 2
      const dataTKISemester2 = {
        labels: ['Pria', 'Wanita'],
        datasets: [{
          label: 'Data TKI Semester 2',
          data: [this.chartData.semester_2.tki_pria, this.chartData.semester_2.tki_wanita],
          backgroundColor: ['#007bff', '#28a745'],
          hoverOffset: 4
        }]
      };

      // Data for TKA Semester 1
      const dataTKASemester1 = {
        labels: ['Pria', 'Wanita'],
        datasets: [{
          label: 'Data TKA Semester 1',
          data: [this.chartData.semester_1.tka_pria, this.chartData.semester_1.tka_wanita],
          backgroundColor: [ '#dc3545','#ffc107'],
          hoverOffset: 4
        }]
      };

      // Data for TKA Semester 2
      const dataTKASemester2 = {
        labels: ['Pria', 'Wanita'],
        datasets: [{
          label: 'Data TKA Semester 2',
          data: [this.chartData.semester_2.tka_pria, this.chartData.semester_2.tka_wanita],
          backgroundColor: ['#dc3545','#ffc107'],
          hoverOffset: 4
        }]
      };

      // Initialize the charts
      new Chart(ctx1, { type: 'pie', data: dataTKISemester1 });
      new Chart(ctx2, { type: 'pie', data: dataTKISemester2 });
      new Chart(ctx3, { type: 'pie', data: dataTKASemester1 });
      new Chart(ctx4, { type: 'pie', data: dataTKASemester2 });
    }
  }
};
</script>

