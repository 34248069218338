<template>
  <div class="lku-form">
   
   

    <div class="container-fluid mt-3">
      <b-button variant="outline-primary" class="mb-3" @click="goBack"><i class="bi bi-arrow-left-circle-fill"></i> Kembali</b-button>
      <br>
    <div class="card shadow mb-4">
      <div class="card-header py-3 d-flex flex-row align-items-center justify-content-between">
        <h6 class="m-0 font-weight-bold text-primary">Formulir Data Laporan</h6>
      </div>
      <div class="card-body">
         <!-- Display error message -->
    <div v-if="errorMessage" class="alert alert-danger">
      {{ errorMessage }}
    </div>

    <!-- Display success message -->
    <div v-if="successMessage" class="alert alert-success">
      {{ successMessage }}
    </div>
    <!-- LKU Form -->
    <form @submit.prevent="createLku">
      
<!-- Form untuk tahun pelaporan dan semester -->
<div class="form-group">
  <label for="tahun_pelaporan">Tahun Pelaporan <small style="color: red;">*</small></label>
  <select
    v-model="lkuData.tahun_pelaporan"
    class="form-control"
    id="tahun_pelaporan"
    @change="checkExistingLaporan"
    required
  >
    <option disabled value="">Pilih Tahun</option>
    <option v-for="year in years" :key="year" :value="year">{{ year }}</option>
  </select>
</div>

<div class="form-group">
  <label for="semester">Semester <small style="color: red;">*</small></label>
  <select
    class="form-control"
    id="semester"
    v-model="lkuData.semester"
    @change="checkExistingLaporan"
    required
  >
    <option disabled value="">Pilih Semester</option>
    <option value="1">Semester 1</option>
    <option value="2">Semester 2</option>
  </select>
</div>

<!-- Alert jika data sudah digunakan -->
<div v-if="laporanExists" class="alert alert-danger">
  Data tahun {{ lkuData.tahun_pelaporan }} semester {{ lkuData.semester }} sudah dilaporkan.
</div>

          <div class="form-group">
            <label for="tanggal_pelaporan">Tanggal Pelaporan <small style="color: red;">*</small></label>
            <input
        type="date"
        class="form-control"
        id="tanggal_pelaporan"
        v-model="lkuData.tanggal_pelaporan"
        name="tanggal_pelaporan"
        :max="today"
      />
          </div>
         

 

          <fieldset>

<div v-for="form in lkuData.single_forms" :key="form.slug">
<label :for="form.slug" class="mt-2">{{ form.label }}
<small v-if="form.required" style="color: red;">*</small>
</label>

<!-- Select Input -->
<select
v-if="form.tipe_inputan.tipe_inputan === 'select'"
class="form-control"
:id="form.slug"
v-model="form[form.slug]"
>
<option disabled value="">{{ form.palceholder }}</option>
<option v-for="option in form.options" :key="option.option_id" :value="option.option_id">
  {{ option.value }}
</option>
</select>

<!-- Radio Input for "Jenis Kelamin" -->
<div v-if="form.tipe_inputan.tipe_inputan === 'radio'" class="mb-3">
<!-- <label :for="form.slug" class="form-label">{{ form.label }}</label> -->
<div v-for="option in form.options" :key="option.option_id" class="form-check">
<input
type="radio"
:id="form.slug"
class="form-check-input"
:value="option.option_id"
v-model="form[form.slug]"
:required="form.required"
/>
<label class="form-check-label" :for="`${form.slug}-${option.option_id}`">
{{ option.value }}
</label>
</div>
</div>

<!-- Number Input -->
<input
v-if="form.tipe_inputan.tipe_inputan === 'number'"
type="number"
class="form-control"
:id="form.slug"
:placeholder="form.placeholder"
v-model="form[form.slug]"
/>

<!-- Input for uang (Rp) -->
<input
v-if="form.tipe_inputan.tipe_inputan === 'uang ( Rp )'"
type="text"
class="form-control"
:id="form.slug"
:placeholder="form.placeholder"
v-model="form[form.slug]"  
@input="onCurrencyInput($event, form.slug)" 
:required="form.required"
/>

<!-- Text Input -->
<input
v-if="form.tipe_inputan.tipe_inputan === 'text'"
type="text"
class="form-control"
:id="form.slug"
:placeholder="form.placeholder"
v-model="form[form.slug]"
/>
<input
v-if="form.tipe_inputan.tipe_inputan === 'percent'"
type="number"
class="form-control"
:id="form.slug"
:placeholder="form.placeholder"
v-model="form[form.slug]"
@input="form[form.slug] = Math.min(Number(form[form.slug]), 100)"  
:required="form.required"
/>
<input
v-if="form.tipe_inputan.tipe_inputan === 'date'"
type="date"
class="form-control"
:id="form.slug"
:placeholder="form.placeholder"
v-model="form[form.slug]"
/>
<input
v-if="form.tipe_inputan.tipe_inputan === 'time'"
type="time"
class="form-control"
:id="form.slug"
:placeholder="form.placeholder"
v-model="form[form.slug]"
/>
<div v-if="form.tipe_inputan.tipe_inputan === 'checkbox'">
<div v-for="option in form.options" :key="option.option_id" class="form-check">
<label class="form-check-label" :for="`option-${form.slug}-${option.option_id}`">
<input 
class="form-check-input" 
type="checkbox" 
:value="option.option_id"
:id="`option-${form.slug}-${option.option_id}`" 
:checked="isOptionSelected(form.slug, option.option_id)"
@change="toggleOption(form.slug, option)"
>

{{ option.value }} {{ option.option_id }}
</label>
</div>
</div>

</div>

</fieldset>
    



<fieldset>
    <!-- Form Grup -->
    <div v-if="lkuData.grup_forms.length">
      <div v-for="group in lkuData.grup_forms" :key="group.grup_id" class="form-group mt-2">
        <legend style="font-size: larger;">{{ group.nama_grup }}</legend>

        <div v-for="form in group.forms" :key="form.slug">
          <label :for="form.slug">
            {{ form.label }} 
            <small v-if="form.required" style="color: red;">* </small>
          </label>

          <!-- Inputan tipe text -->
          <input
            v-if="form.tipe_inputan.tipe_inputan === 'text'"
            type="text"
            class="form-control"
            :id="form.slug"
            :placeholder="form.placeholder"
            v-model="lkuData.grup_forms.find(g => g.grup_id === group.grup_id).forms.find(f => f.slug === form.slug)[form.slug]" 
         />
         <input
            v-if="form.tipe_inputan.tipe_inputan === 'number'"
            type="number"
            class="form-control"
            :id="form.slug"
            :placeholder="form.placeholder"
            v-model="lkuData.grup_forms.find(g => g.grup_id === group.grup_id).forms.find(f => f.slug === form.slug)[form.slug]" 
            @input="form[form.slug] = Math(Number(form[form.slug]))"  
         />
        
         <input
            v-if="form.tipe_inputan.tipe_inputan === 'uang ( Rp )'"
            type="number"
            class="form-control"
            :id="form.slug"
            :placeholder="form.placeholder"
            v-model="lkuData.grup_forms.find(g => g.grup_id === group.grup_id).forms.find(f => f.slug === form.slug)[form.slug]" 
         />
   <!-- Inputan tipe percent -->
   <input
  v-if="form.tipe_inputan.tipe_inputan === 'percent'"
  type="number"
  class="form-control"
  :id="form.slug"
  :placeholder="form.placeholder"
  v-model="lkuData.grup_forms.find(g => g.grup_id === group.grup_id).forms.find(f => f.slug === form.slug)[form.slug]"
  @input="form[form.slug] = Math.min(Math.max(Number(form[form.slug]), 1), 100)"  
  min="1"
  max="100"
  step="0.1"
  :required="form.required"
/>
<input
            v-if="form.tipe_inputan.tipe_inputan === 'date'"
            type="date"
            class="form-control"
            :id="form.slug"
            :placeholder="form.placeholder"
            v-model="lkuData.grup_forms.find(g => g.grup_id === group.grup_id).forms.find(f => f.slug === form.slug)[form.slug]" 
         />

         <input
        v-if="form.tipe_inputan.tipe_inputan === 'time'"
        type="time"
        class="form-control"
        :id="form.slug"
        :placeholder="form.placeholder"
        v-model="lkuData.grup_forms.find(g => g.grup_id === group.grup_id).forms.find(f => f.slug === form.slug)[form.slug]" 
        />
     <!-- Select Input -->
     <select
          v-if="form.tipe_inputan.tipe_inputan === 'select' && Array.isArray(form.options)"
          class="form-control"
          :id="form.slug"
          v-model="form[form.slug]"
        >
          <option disabled value="">{{ form.placeholder }}</option>
          <option
            v-for="option in form.options"
            :key="option.option_id"
            :value="option.option_id"
          >
            {{ option.value }}
          </option>
        </select>
<!-- Radio Input -->
<div v-if="form.tipe_inputan.tipe_inputan === 'radio' && Array.isArray(form.options)">

  <div :id="form.slug" class="form-check">
    <div
      v-for="option in form.options"
      :key="option.option_id"
      class="form-check-inline"
    >
      <input
        type="radio"
        :id="`${form.slug}_${option.option_id}`"
        :name="form.slug"
        class="form-check-input"
        :value="option.option_id"
        v-model="form[form.slug]"
      />
      <label
        class="form-check-label"
        :for="`${form.slug}_${option.option_id}`"
      >
        {{ option.value }}
      </label>
    </div>
  </div>
</div>


<!-- Checkbox Input -->
<div v-if="form.tipe_inputan.tipe_inputan === 'checkbox'">
  <div v-for="option in form.options" :key="option.option_id" class="form-check">
    <input
      type="checkbox"
      class="form-check-input"
      :id="`${form.slug}_${option.option_id}`"
      :value="option.option_id"
      :checked="isChecked(form.slug, option.option_id)"
      @change="toggleCheckbox(form.slug, option.option_id)"
    />
    <label class="form-check-label" :for="`${form.slug}_${option.option_id}`">
      {{ option.value }}
    </label>
  </div>
</div>
        </div>
      </div>
    </div>
  </fieldset>


  <fieldset>  

    <legend style="font-size: larger;">Pilih Fasilitas<small style="color: red;">*</small></legend>
<div class="form-check" v-for="fasilitas in forms.fasilitas" :key="fasilitas.fasilitas_id">
  <input
    type="checkbox"
    class="form-check-input"
    :id="'fasilitas_' + fasilitas.fasilitas_id"
    :value="fasilitas.fasilitas_id"
    :checked="isFasilitasSelected(fasilitas.fasilitas_id)" 
    @change="toggleFasilitas(fasilitas)" 
  />
  <label class="form-check-label" :for="'fasilitas_' + fasilitas.fasilitas_id">
    {{ fasilitas.nama_fasilitas }}
  </label>
</div>
  
 


  <!-- Checkbox for "Punya Ruang Pertemuan" -->
  <div 
  class="form-group form-check"
>
<input
  v-model="lkuData.punya_ruang_pertemuan"
  type="checkbox"
  class="form-check-input"
  id="punya_ruang_pertemuan"
  name="punya_ruang_pertemuan"
  :true-value="1"
  :false-value="null"
/>

  <label class="form-check-label" for="punya_ruang_pertemuan">
    Ruang Pertemuan
  </label>
</div>

<!-- Meeting Room Input Table (Shown if "punya_ruang_pertemuan" is true) -->
<div v-if="lkuData.punya_ruang_pertemuan">
  <table class="table table-striped">
    <thead>
      <tr>
        <td>
          <div class="form-group">
            <select v-model="new_ruang_pertemuans.jenis_ruang_pertemuan" class="form-control">
              <option value="Ruang Pertemuan">Ruang Pertemuan</option>
              <option value="Meeting Room">Meeting Room</option>
              <option value="Ball Room">Ball Room</option>
            </select>
            <small v-if="validationErrors.jenis_ruang_pertemuan" class="text-danger">*Ruang pertemuan harus diisi*</small>
          </div>
        </td>
        <td>
          <div class="form-group">
            <input v-model="new_ruang_pertemuans.nama_ruang_pertemuan" type="text" class="form-control" placeholder="Nama Ruang">
            <small v-if="validationErrors.nama_ruang_pertemuan" class="text-danger">*Nama ruang harus diisi*</small>
          </div>
        </td>
        <td>
          <div class="form-group">
            <input v-model="new_ruang_pertemuans.kapasitas_ruang_pertemuan" type="number" class="form-control" placeholder="Kapasitas Ruang">
            <small v-if="validationErrors.kapasitas_ruang_pertemuan" class="text-danger">*Kapasitas harus diisi*</small>
          </div>
        </td>
        <td>
          <button type="button" class="btn" @click="addRuangPertemuan">
            <i class="bi bi-plus-square text-success" style="font-size: larger;"></i>
          </button>
        </td>
      </tr>
    </thead>
    <tbody>
      <tr v-for="(ruang, index) in lkuData.ruang_pertemuans" :key="index">
        <td>{{ ruang.jenis_ruang_pertemuan }}</td>
        <td>{{ ruang.nama_ruang_pertemuan }}</td>
        <td>{{ ruang.kapasitas_ruang_pertemuan }}</td>
        
        <td>
           <button type="button" class="btn btn-danger" @click="hapusRuang(index)">
                            Hapus
                          </button>
                        </td>
      </tr>
    </tbody>
  </table>
</div>

          </fieldset>


      <button type="submit" class="btn btn-primary mt-2">SIMPAN</button>
    </form>
 <!-- Loading spinner -->
<div v-if="loading" class="loading-overlay">
      <div class="spinner"></div>
      <p>Tunggu Sebentar ...</p>
    </div>
    </div>
    </div>
    </div>
  </div>
</template>
<script>
import axios from 'axios';

export default {
  data() {
    const currentYear = new Date().getFullYear();
    const startYear = currentYear - 4;
    const endYear = currentYear;
    return {
      
      forms: {
        fasilitas: [],
        single_forms: [],
        grup_forms: [],
     
      },
      lkuData: { 
        punya_ruang_pertemuan: null,
        ruang_pertemuans: [],
        
      }, // Holds the LKU data, including dynamic form fields
      loading: false, // Controls the loading spinner
      errorMessage: '', // Error message
      successMessage: '', // Success message
      formData: {
        tahun_pelaporan: '',
        tanggal_pelaporan: '',
        semester: '',
        punya_ruang_pertemuan: null,
        ruang_pertemuans: [],
        fasilitas_id: [], // Initialize as an empty array
        single_forms: [],
        grup_forms: [],
        
      },
      new_ruang_pertemuans: {
        jenis_ruang_pertemuan: '',
        nama_ruang_pertemuan: '',
        kapasitas_ruang_pertemuan: '',
      },
      selectedSingleForms: {},
      laporanExists: false,
      unitUsahaData: null,  // Initialize to null to avoid undefined errors
      years: Array.from({ length: endYear - startYear + 1 }, (_, i) => startYear + i),
      today: new Date().toISOString().split('T')[0],
      ruang_pertemuans: [],
      validationErrors: {
        jenis_ruang_pertemuan: false,
        nama_ruang_pertemuan: false,
        kapasitas_ruang_pertemuan: false,
      }, // Holds dynamic form data, like checkboxes
    };
  },

  methods: {
   
  checkExistingLaporan() {
  if (!this.unitUsahaData) {
    console.log('unitUsahaData is null or undefined');
    return;
  }

  const tahun_pelaporan = Number(this.lkuData.tahun_pelaporan);
  const semester = Number(this.lkuData.semester);

  console.log('Selected tahun_pelaporan:', tahun_pelaporan);
  console.log('Selected semester:', semester);
  console.log('Existing laporan:', this.unitUsahaData.lapor_lku);

  // Check if the combination of tahun_pelaporan and semester exists
  this.laporanExists = this.unitUsahaData.lapor_lku.some((laporan) => {
    const laporanTahun = Number(laporan.tahun_pelaporan);
    const laporanSemester = Number(laporan.semester);
    const statusLku = Number(laporan.status_lku); // Ensure status_lku is treated as a number

    // Only match if status_lku is NOT 2
    const match = (statusLku !== 2) && (laporanTahun === tahun_pelaporan && laporanSemester === semester);
    
    console.log(`Checking laporan: ${laporan.tahun_pelaporan} - Semester ${laporan.semester}`, 'Match:', match);

    return match; // Return true if the condition is met
  });

  console.log('laporanExists:', this.laporanExists);
},

    // Format the number to Indonesian currency (Rp with thousands separators)
  formatCurrency(value) {
    if (!value) return '';
    return new Intl.NumberFormat('id-ID', {
      style: 'currency',
      currency: 'IDR',
      minimumFractionDigits: 0
    }).format(value).replace('Rp', 'Rp '); // Fix the spacing around 'Rp'
  },

  // Handle the input for currency formatting
  onCurrencyInput(event, slug) {
    // Remove non-numeric characters (keeping only numbers)
    const numericValue = event.target.value.replace(/[^0-9]/g, '');
    
    // Update form model with the numeric value, convert to an integer
    this.$set(this.form, slug, parseInt(numericValue) || 0); 

    // After updating the model, we need to update the input field display
    // Set the formatted currency back into the input field
    this.$nextTick(() => {
      event.target.value = this.formatCurrency(this.form[slug]);
    });
  },
     // Check if a facility is already selected
  isFasilitasSelected(fasilitas_id) {
    return this.lkuData.fasilitas.some(fasilitas => fasilitas.fasilitas_id == fasilitas_id);
  },

  // Toggle the selection of a facility
  toggleFasilitas(fasilitas) {
    const index = this.lkuData.fasilitas.findIndex(f => f.fasilitas_id == fasilitas.fasilitas_id);
    if (index > -1) {
      // If already selected, remove from the array
      this.lkuData.fasilitas.splice(index, 1);
    } else {
      // If not selected, add to the array
      this.lkuData.fasilitas.push({ fasilitas_id: fasilitas.fasilitas_id, nama_fasilitas: fasilitas.nama_fasilitas });
    }
  }, 
  
  //single-forms
isOptionSelected(slug, option_id) {
  const form = this.lkuData.single_forms.find(f => f.slug === slug);
  if (form && Array.isArray(form[slug])) {
    return form[slug].some(opt => 
      parseInt(opt.option_id || opt.optioin_id, 10) === parseInt(option_id, 10)
    );
  }
  return false;
},

// Toggle the selection of a checkbox option
toggleOption(slug, option) {
  const form = this.lkuData.single_forms.find(f => f.slug === slug);
  if (form) {
    if (!Array.isArray(form[slug])) {
      this.$set(form, slug, []);
    }
    const index = form[slug].findIndex(opt => 
      parseInt(opt.option_id || opt.optioin_id, 10) === parseInt(option.option_id, 10)
    );

    if (index > -1) {
      form[slug].splice(index, 1);
    } else {
      form[slug].push({ option_id: option.option_id });
    }

    console.log(`Updated form[${slug}]:`, form[slug]);
  }
},
  //end-singleforms
  
  
    goBack() {
      this.$router.go(-1);  // This will navigate to the previous page.
    },
    // Fetch Fasilitas Forms Data
   

    async fetchLkuData() {
  this.isLoading = true;
  try {
    const token = localStorage.getItem('token');
    const id = this.$route.query.id;

    if (!id) {
      console.error('ID is missing in the route query parameters.');
      return;
    }

    const response = await axios.get(`${process.env.VUE_APP_API_BASE_URL}/api/v1/last-lapor-lku/${id}`, {
      headers: {
        'Accept': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
    });

    if (response.data && response.data.data) {
      this.lkuData = response.data.data;

      // Convert date from DD-MM-YYYY to YYYY-MM-DD for the input field
if (this.lkuData.tanggal_pelaporan) {
  const [day, month, year] = this.lkuData.tanggal_pelaporan.split('-');
  this.lkuData.tanggal_pelaporan = `${year}-${month}-${day}`;
}

           // Iterasi untuk memformat tanggal jika tipe input adalah 'date'
           if (Array.isArray(this.lkuData.single_forms)) {
    this.lkuData.single_forms.forEach(form => {
      if (form.tipe_inputan.tipe_inputan === "date") {
        const dateField = form[form.slug];
        if (dateField) {
          // Format tanggal ke format "YYYY-MM-DD" (misalnya "2024-12-02")
          const [day, month, year] = dateField.split("/");
          const formattedDate = `${year}-${month}-${day}`;
          this.$set(form, form.slug, formattedDate); // Set the formatted date
        }
      }
    });
  }

      // Jika 'ruang_pertemuans' kosong, set 'punya_ruang_pertemuan' menjadi 0 dan ruang_pertemuans menjadi array kosong
      if (this.lkuData.ruang_pertemuans.length === 0) {
        this.lkuData.punya_ruang_pertemuan = 0;
        this.lkuData.ruang_pertemuans = [];
      }
      
      // Jika punya_ruang_pertemuan === 0, pastikan ruang_pertemuans kosong
      if (this.lkuData.punya_ruang_pertemuan === 0) {
        this.lkuData.ruang_pertemuans = [];
      }

    } else {
      throw new Error('LKU data is not available.');
    }
  } catch (error) {
    this.errorMessage = error.response?.data?.message || error.message || 'Failed to fetch LKU data.';
    console.error(error);
  } finally {
    this.isLoading = false;
  }
},

    async fetchFasilitas() {
  this.loading = true; // Set loading to true when submission starts

  try {
    const token = localStorage.getItem('token');
    const id = this.$route.query.id;

    if (!id) {
      console.error('ID is missing in the route query parameters.');
      return;
    }

    // Fetching unit usaha data
    const response = await axios.get(`${process.env.VUE_APP_API_BASE_URL}/api/v1/unit-usaha/${id}`, {
      headers: {
        'Accept': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
    });

    this.unitUsahaData = response.data.data;

   

    // Fetching fasilitas forms data
    const fasilitasResponse = await axios.get(`${process.env.VUE_APP_API_BASE_URL}/api/v1/form/lapor-lku/${id}`, {
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });

    const fasilitasData = fasilitasResponse.data;
    if (fasilitasData.status && fasilitasData.code === 200) {
      this.forms = { ...fasilitasData.forms };

      // Initialize formData for checkboxes in single_forms
      this.forms.single_forms.forEach(form => {
        if (form.tipe_inputan.tipe_inputan === 'checkbox') {
          this.$set(this.formData, form.slug, []); // Initialize checkbox as an empty array
        }
      });
       // Initialize formData for grup_forms
this.forms.grup_forms.forEach(group => {
  group.forms.forEach(form => {
    if (form.tipe_inputan.tipe_inputan === 'checkbox') {
      this.$set(this.formData, form.slug, []); // Initialize checkbox as an empty array
    } else if (form.tipe_inputan.tipe_inputan === 'text' || form.tipe_inputan.tipe_inputan === 'number' || form.tipe_inputan.tipe_inputan === 'select' || form.tipe_inputan.tipe_inputan === 'radio') {
      this.$set(this.formData, form.slug, ''); // Initialize other types as empty string
    }
  });
});

     
    } else {
      console.error('Data not valid:', fasilitasData);
    }
  } catch (error) {
    console.error('Error fetching data:', error);
  }
  finally {
    this.loading = false; // Reset loading state after submission completes
  }
},
   // Format date to dd/mm/yyyy
   formatDate(date) {
  if (!date) return "";
  // Check if the date is in yyyy-mm-dd format and convert it to dd/mm/yyyy
  if (date.includes("-")) {
    const [year, month, day] = date.split("-");
    return `${day}-${month}-${year}`;
  }
  // If the date is already in dd/mm/yyyy format, return it as is
  return date;
},
formatDates(date) {
  if (!date) return "";
  // Check if the date is in yyyy-mm-dd format and convert it to dd/mm/yyyy
  if (date.includes("-")) {
    const [year, month, day] = date.split("-");
    return `${day}/${month}/${year}`;  // Change '-' to '/'
  }
  // If the date is already in dd/mm/yyyy format, return it as is
  return date;
},
async createLku() {
  this.isLoading = true;
  try {
    const token = localStorage.getItem('token');
    const id = this.$route.query.id;

    if (!id) {
      console.error('ID is missing in the route query parameters.');
      return;
    }

    // Prepare data for POST
    const formDataToCreate = {};

       
    if (Array.isArray(this.lkuData.single_forms)) {
  this.lkuData.single_forms.forEach(form => {
    const inputType = form.tipe_inputan.tipe_inputan;

    if (inputType === "checkbox") {
      // Proses data checkbox
      formDataToCreate[form.slug] = Array.isArray(form[form.slug])
        ? form[form.slug]
            .filter(opt => opt && (opt.option_id || opt.optioin_id)) // Hapus null/undefined
            .map(opt => parseInt(opt.option_id || opt.optioin_id, 10)) // Ambil hanya `option_id`
        : [];
    } else if (inputType === "date") {
      // Format data tanggal
      const dateField = form[form.slug];
      if (dateField) {
        this.$set(formDataToCreate, form.slug, this.formatDates(dateField));
      }
    } else {
      // Proses tipe input lain
      formDataToCreate[form.slug] = form[form.slug] || ""; // Pastikan nilai string
    }
  });
}
  

    // Process group_forms
    if (Array.isArray(this.lkuData.grup_forms)) {
      this.lkuData.grup_forms.forEach(group => {
        if (Array.isArray(group.forms)) {
          group.forms.forEach(form => {
            const value = group.forms.find(f => f.slug === form.slug);
            if (value) {
              formDataToCreate[value.slug] = value[value.slug];
            }
          });
        }
      });
    }

    // Format the tanggal_pelaporan field before submission
    if (this.lkuData.tanggal_pelaporan) {
      this.lkuData.tanggal_pelaporan = this.formatDate(this.lkuData.tanggal_pelaporan);
    }

   

    // Prepare additional data from lkuData
    Object.keys(this.lkuData).forEach(key => {
      if (key !== 'single_forms' && key !== 'grup_forms' && key !== 'ruang_pertemuans' && key !== 'fasilitas') {
        formDataToCreate[key] = this.lkuData[key];
      }
    });

    // Handle ruang_pertemuans
    if (this.lkuData.punya_ruang_pertemuan === null || this.lkuData.punya_ruang_pertemuan === ''|| this.lkuData.punya_ruang_pertemuan === false || this.lkuData.punya_ruang_pertemuan === 0) {
      delete formDataToCreate.ruang_pertemuans;  // If punya_ruang_pertemuan is null or empty, delete ruang_pertemuans from formData
    } else {
      // Ensure that ruang_pertemuans is only included if it's not empty and valid
      if (Array.isArray(this.lkuData.ruang_pertemuans) && this.lkuData.ruang_pertemuans.length > 0) {
        formDataToCreate.ruang_pertemuans = this.lkuData.ruang_pertemuans;  // Only add ruang_pertemuans if valid
      } else {
        formDataToCreate.ruang_pertemuans = [];  // Set to null if no valid ruang_pertemuans
      }
    }

    // Handle fasilitas (convert fasilitas_id to integers)
    if (Array.isArray(this.lkuData.fasilitas)) {
      formDataToCreate.fasilitas_id = this.lkuData.fasilitas.map(fasilitas => parseInt(fasilitas.fasilitas_id, 10));
    }

    // Log for debugging
    console.log('Data yang akan dibuat:', formDataToCreate);

    // Send POST request
    const response = await axios.post(
      `${process.env.VUE_APP_API_BASE_URL}/api/v1/lapor-lku/${id}`,
      formDataToCreate,  // Send the constructed form data
      {
        headers: {
          'Accept': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
      }
    );

    console.log('Server response:', response);

    // Redirect to the LKU dashboard page
    this.$router.push({ path: `/dashboard/unit-usaha/lku?id=${id}`, query: { success: true } });

    // Display success toast
    this.$bvToast.toast('Laporan LKU berhasil dibuat.', {
      variant: 'success',
      solid: true,
    });
  } catch (error) {
    if (error.response && error.response.data && error.response.data.code === 403) {
      // Display the specific error message from the API in a toast
      this.$bvToast.toast(error.response.data.errors, {
        variant: 'danger',
        solid: true,
      });
    } else {
      // Fallback for other errors
      console.error('Error creating LKU', error);
      this.$bvToast.toast('Gagal membuat Laporan LKU.', {
        variant: 'danger',
        solid: true,
      });
    }
  } finally {
    this.isLoading = false; // Reset loading state after submission completes
  }
},

addRuangPertemuan() {
  if (!this.new_ruang_pertemuans.jenis_ruang_pertemuan || !this.new_ruang_pertemuans.nama_ruang_pertemuan || !this.new_ruang_pertemuans.kapasitas_ruang_pertemuan) {
    // Handle validation error
    this.validationErrors = {
      jenis_ruang_pertemuan: !this.new_ruang_pertemuans.jenis_ruang_pertemuan,
      nama_ruang_pertemuan: !this.new_ruang_pertemuans.nama_ruang_pertemuan,
      kapasitas_ruang_pertemuan: !this.new_ruang_pertemuans.kapasitas_ruang_pertemuan,
    };
    return;
  }
  // Push new meeting room data into lkuData.ruang_pertemuans
  this.lkuData.ruang_pertemuans.push({ ...this.new_ruang_pertemuans });
  // Reset new_ruang_pertemuans and validation errors
  this.new_ruang_pertemuans = { jenis_ruang_pertemuan: '', nama_ruang_pertemuan: '', kapasitas_ruang_pertemuan: '' };
  this.validationErrors = { jenis_ruang_pertemuan: false, nama_ruang_pertemuan: false, kapasitas_ruang_pertemuan: false };
},

hapusRuang(index) {

this.lkuData.ruang_pertemuans.splice(index, 1);

},
// Method to log the current room data for debugging purposes
logRuangData() {
  console.log('Current meeting rooms:', this.ruang_pertemuans);
},
  

    // Process formData after fetching form structure
    initializeFormData() {
      this.lkuData.single_forms.forEach((form) => {
        this.$set(this.formData, form.slug, form[form.slug]);
      });
    },
  },

  created() {
    this.fetchLkuData();
    this.fetchFasilitas();
  },
  
};
</script>




<style scoped>
/* Add your scoped styles here if needed */
.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.8);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 9999;
}

.spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-left-color: #28a745;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.alert {
  margin-top: 20px;
}
</style>
