<template>
  <div>
    <b-navbar toggleable="lg" style="background-color: #05274B !important;">
      <b-navbar-brand to="/">
        <img src="@/assets/images/logo-white.png" alt="DISPAREKRAF" style="max-height: 70px; margin-left: 15px;">
      </b-navbar-brand>

      <b-navbar-toggle target="nav-collapse">
        <b-icon icon="list" scale="2" style="color: white;"></b-icon>
      </b-navbar-toggle>

      <b-collapse id="nav-collapse" is-nav>
        <b-navbar-nav>
          <b-nav-item to="/">
            <p style="color: white; font-size: 22px;" class="mt-2">Beranda</p>
          </b-nav-item>
        </b-navbar-nav>

        <!-- Right aligned nav items -->
        <b-navbar-nav class="ml-auto">
          <!-- Registration link -->
          <b-nav-item to="/register">
            <p style="color: white; font-size: 22px;" class="mt-2">Pendaftaran</p>
          </b-nav-item>
          
          <!-- Login link -->
          <b-nav-item to="/login">
            <b-button style="background-color: #EA6645; color: white; font-size: 22px;">
              Masuk
            </b-button>
          </b-nav-item>

          <!-- Logged-in user dropdown -->
          <b-nav-item-dropdown v-show="false" right>
            <template #button-content>
              <em class="text-white"><b-icon-person></b-icon-person> Nama User</em>
            </template>
            <b-dropdown-item href="#"><b-icon-person></b-icon-person> Profile</b-dropdown-item>
            <b-dropdown-item href="#"><b-icon-door-closed></b-icon-door-closed> Sign Out</b-dropdown-item>
          </b-nav-item-dropdown>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
  </div>
</template>

<script>
export default {
  name: 'NavbarMenu',
};
</script>

<style>
</style>
