<template>
  <div>
    <div class="col">
      <h1 class="h3 ml-3 mb-1 text-gray-800">Edit Data Unit Usaha</h1>
    </div>

    <div class="container-fluid mt-3">
      <div class="row mt-3">
        <div class="col-xl-12 col-lg-7">
          <div class="card shadow mb-4">
            <div class="card-header py-3 d-flex flex-row align-items-center justify-content-between">
              <h6 class="m-0 font-weight-bold text-primary">Data Usaha</h6>
            </div>
            <div class="card-body">
              <form @submit.prevent="updateUnitUsaha">
                 <!-- Error Display Section -->
              <div v-if="errorMessage" class="alert alert-danger" role="alert">
                {{ errorMessage }}
              </div>
                <!-- Displaying and editing the fetched data -->
                <fieldset>
                  <legend>Data Usaha</legend>
                  <div class="form-group">
                    <label for="nama_unit_usaha">Nama Unit Usaha</label>
                    <input
                      type="text"
                      id="nama_unit_usaha"
                      v-model="unitUsahaData.data.nama_unit_usaha"
                      class="form-control"
                      disabled
                    />
                  </div>
                  <div class="form-group">
                    <label for="tipe_usaha">Master Tipe Unit Usaha</label>
                    <input
                      type="text"
                      id="tipe_usaha"
                      v-model="unitUsahaData.data.tipe_usaha.master_tipe_usaha.master_usaha"
                      class="form-control"
                      disabled
                    />
                  </div>
                  <div class="form-group">
                    <label for="tipe_usaha">Tipe Unit Usaha</label>
                    <input
                      type="text"
                      id="tipe_usaha"
                      v-model="unitUsahaData.data.tipe_usaha.nama_tipeusaha
"
                      class="form-control"
                      disabled
                    />
                  </div>

                  <div class="form-group">
                    <label for="kabupaten">Kabupaten</label>
                    <input
                      type="text"
                      id="kabupaten"
                      v-model="unitUsahaData.data.kabupaten.kabupaten"
                      class="form-control"
                      disabled
                    />
                  </div>

                  <div class="form-group">
                    <label for="kecamatan">Kecamatan</label>
                    <input
                      type="text"
                      id="kecamatan"
                      v-model="unitUsahaData.data.kecamatan.kecamatan"
                      class="form-control"
                      disabled
                    />
                  </div>

                  <div class="form-group">
                    <label for="kelurahan">Kelurahan</label>
                    <input
                      type="text"
                      id="kelurahan"
                      v-model="unitUsahaData.data.kelurahan.kelurahan"
                      class="form-control"
                      disabled
                    />
                  </div>

                  <div class="form-group">
                    <label for="alamat">Alamat Usaha</label>
                    <textarea
                      class="form-control"
                      id="alamat"
                      v-model="unitUsahaData.data.alamat"
                      disabled
                      rows="5"
                    ></textarea>
                  </div>

                  <div class="form-group">
                    <label for="nopd">NOPD</label>
                    <input
                      type="text"
                      id="nopd"
                      v-model="unitUsahaData.data.nopd"
                      class="form-control"
                      disabled
                    />
                  </div>
                </fieldset>

                <!-- Data Penanggung Jawab -->
                <fieldset>
                  <div class="form-group">
  <label for="pengurusSelect">Pilih Pengurus</label>
  <select
    id="pengurusSelect"
    v-model="unitUsahaData.data.penanggung_jawab.pengurus_id"
    class="form-control"
   
  >
    <option value="" disabled selected>Pilih Penanggung Jawab</option>
    <option v-for="p in pengurus" :key="p.pengurus_id" :value="p.pengurus_id">
      {{ p.nama_pengurus }} - {{ p.jabatan }} 
    </option>
  </select>
</div>

<div class="form-group">
  <label for="nohp_penanggung_jawab">No. HP Penanggung Jawab <small style="color: red;">*</small></label>
  <input
    v-model="unitUsahaData.data.penanggung_jawab.kontak_pengurus"
    type="number"
    class="form-control"
    id="nohp_penanggung_jawab"
    maxlength="13"
    placeholder="Masukan No. HP Penanggung Jawab"
    required
  >
</div>


           

                  <div class="form-group">
                    <label for="telpon_kantor">No. Telepon Kantor <small style="color: red;">*</small></label>
                    <input
                      v-model.trim="unitUsahaData.data.telpon_kantor"
                      type="text"
                      class="form-control"
                      id="telpon_kantor"
                      placeholder="Masukan No. Telepon Kantor"
                    />
                  </div>

                  <div class="form-group">
                    <label for="email">Email</label>
                    <input
                      v-model.trim="unitUsahaData.data.email"
                      type="email"
                      class="form-control"
                      id="email"
                      placeholder="Masukan Email"
                    />
                  </div>

                  <div class="form-group">
                    <label for="website">Website</label>
                    <input
                      v-model.trim="unitUsahaData.data.website"
                      type="url"
                      class="form-control"
                      id="website"
                      placeholder="Masukan Website contoh (https://website.com)"
                    />
                  </div>

                 

                  <div class="form-group">
                    <label for="luas_tanah">Luas Tanah (m<sup>2</sup>) <small style="color: red;">*</small></label>
                    <input
                      v-model.trim="unitUsahaData.data.luas_tanah"
                      type="text"
                      class="form-control"
                      name="luas_tanah"
                      id="luas_tanah"
                      placeholder="Contoh: 300,45"
                      required
                    />
                  </div>

                  <div class="form-group">
                    <label for="luas_bangunan">Luas Bangunan (m<sup>2</sup>) <small style="color: red;">*</small></label>
                    <input
                      v-model.trim="unitUsahaData.data.luas_bangunan"
                      type="text"
                      class="form-control"
                      id="luas_bangunan"
                      name="luas_bangunan"
                      placeholder="Contoh: 300,45"
                      required
                    />
                  </div>

                  <div class="form-group">
                    <label for="instagram">Instagram</label>
                    <input
                      v-model.trim="unitUsahaData.data.instagram"
                      type="text"
                      class="form-control"
                      id="instagram"
                      placeholder="Masukan Akun Media Sosial"
                    />
                  </div>
                </fieldset>

              
               
                <fieldset>
  <legend>Data Tenaga Kerja</legend>

  <!-- TKI (Tenaga Kerja Indonesia) Section -->
  <h5>TKI (Tenaga Kerja Indonesia)</h5>

  <div class="form-group">
    <div class="row">
      <div class="col-md-6">
        <label for="tki_pria">
          Jumlah Karyawan Pria <small style="color: red;">*</small>
        </label>
        <input
          type="text"
          v-model.trim="unitUsahaData.data.tki_pria"
          v-mask="'###############0'"
          class="form-control"
          id="tki_pria"
          placeholder="Masukan Jumlah Karyawan Pria"
        />
      </div>

      <div class="col-md-6">
        <label for="tki_wanita">
          Jumlah Karyawan Wanita <small style="color: red;">*</small>
        </label>
        <input
          type="text"
          v-model.trim="unitUsahaData.data.tki_wanita"
          v-mask="'###############0'"
          class="form-control"
          id="tki_wanita"
          placeholder="Masukan Jumlah Karyawan Wanita"
        />
      </div>
    </div>
  </div>

  <!-- TKA (Tenaga Kerja Asing) Section -->
  <h5>TKA (Tenaga Kerja Asing)</h5>

  <div class="form-group">
    <div class="row">
      <div class="col-md-6">
        <label for="tka_pria">
          Jumlah Karyawan Pria <small style="color: red;">*</small>
        </label>
        <input
          type="text"
          v-model.trim="unitUsahaData.data.tka_pria"
          v-mask="'###############0'"
          class="form-control"
          id="tka_pria"
          placeholder="Masukan Jumlah Karyawan Pria"
        />
      </div>

      <div class="col-md-6">
        <label for="tka_wanita">
          Jumlah Karyawan Wanita <small style="color: red;">*</small>
        </label>
        <input
          type="text"
          v-model.trim="unitUsahaData.data.tka_wanita"
          v-mask="'###############0'"
          class="form-control"
          id="tka_wanita"
          name="tka_wanita"
          placeholder="Masukan Jumlah Karyawan Wanita"
        />
      </div>
    </div>
  </div>
</fieldset>

<fieldset>
    <legend>Data Legalitas Usaha</legend>

    <div class="form-group">
      <label for="jenis_izin">Jenis Izin <small style="color: red;">*</small></label>
      <select v-model.trim="unitUsahaData.data.jenis_izin" class="form-control" id="jenis_izin" name="jenis_izin">
        <option value="">Pilih Jenis Izin</option>
        <option value="TDUP PTSP">TDUP PTSP</option>
        <option value="OSS RBA">OSS RBA</option>
      </select>
    </div>

    <div class="form-group">
      <label for="nomor_izin">Nomor Izin <small style="color: red;">*</small></label>
      <input
        v-model.trim="unitUsahaData.data.nomor_izin"
        type="text"
        class="form-control"
        id="nomor_izin"
        name="nomor_izin"
        placeholder="Masukan Nomor Izin"
      />
    </div>

    <div class="form-group">
      <label for="tanggal_terbit_izin">Tanggal Terbit Izin <small style="color: red;">*</small></label>
      <input
        v-model.trim="unitUsahaData.data.tanggal_terbit_izin"
       type="date"
        class="form-control col-sm-6"
        id="tanggal_terbit_izin"
        name="tanggal_terbit_izin"
        placeholder="Minimal tahun 2015"
        autocomplete="off"
      />
    </div>

    <div class="form-group">
      <label for="klasifikasi_resiko">Klasifikasi Resiko <small style="color: red;">*</small></label>
      <select v-model.trim="unitUsahaData.data.klasifikasi_resiko" class="form-control" id="klasifikasi_resiko" name="klasifikasi_resiko">
        <option value="">Pilih Klasifikasi Resiko</option>
        <option value="rendah">Rendah</option>
        <option value="menengah rendah">Menengah Rendah</option>
        <option value="menengah tinggi">Menengah Tinggi</option>
        <option value="tinggi">Tinggi</option>
      </select>
    </div>

    <fieldset>
  <legend>File Upload</legend>

  <!-- Perizinan File Upload -->
  <div class="form-group">
    <label for="izinFile">Upload File Perizinan (PDF) <small style="color: red;">*</small></label>
    <b-form-file
      ref="izinFile"
      id="izinFile"
      name="file_perizinan"
      placeholder="Maksimal 1MB"
      accept=".pdf"
      @change="handleFileUpload('izin', $event)"
   
    ></b-form-file>
    
    <div class="progress mt-3" v-if="uploadProgress.izin">
      <div
        class="progress-bar progress-bar-striped progress-bar-animated"
        role="progressbar"
        :style="{ width: uploadProgress.izin + '%' }"
      ></div>
    </div>
    <div v-if="uploadedFiles.izinFile" class="alert alert-success mt-3">
      <button type="button" class="btn float-right" @click="removeFile('izin')">
        <i class="bi bi-trash-fill text-danger" style="font-size: 1.5rem;"></i>
      </button>
      Nama File: <span>{{ uploadedFiles.izinFile.name }}</span> <br />
      Ukuran File: <span>{{ (uploadedFiles.izinFile.size / 1024).toFixed(2) }} KB</span>
    </div>
         
 <!-- View Document Button -->
<div v-if="unitUsahaData.data.file_perizinan" class="alert alert-success mt-3" >
  <a
    :href="`${url}/storage/${unitUsahaData.data.file_perizinan}`"
    target="_blank"
    style="text-decoration: none; color: inherit;"
   
  >
    <span>Lihat Dokumen</span>
  </a>
</div>
  </div>

  
</fieldset>
    <div class="form-group form-check">
      <input
        v-model.trim="unitUsahaData.data.sertifikat_laik_sehat"
        type="checkbox"
        class="form-check-input"
        id="sertifikat_laik_sehat"
        name="sertifikat_laik_sehat"
      />
      <label class="form-check-label" for="sertifikat_laik_sehat">Sertifikat Laik Sehat</label>
    </div>
 
    <div class="form-group">
      <label for="masa_berlaku_laik_sehat">Masa Berlaku Sertifikat Laik Sehat:</label>
      <input
        v-model.trim="unitUsahaData.data.masa_berlaku_laik_sehat"
      type="date"
        class="form-control col-sm-6"
        id="masa_berlaku_laik_sehat"
        name="masa_berlaku_laik_sehat"
        autocomplete="off"
         :disabled="!unitUsahaData.data.sertifikat_laik_sehat"
      />
    </div>
    <label for="slsFile" class="form-label">Upload SLS (PDF)</label>
    <b-form-file
      ref="slsFile"
      id="slsFile"
      placeholder="Maksimal 1MB"
      name="sls_file"
      accept=".pdf"
      @change="handleFileUpload('sls', $event)"
      :disabled="!unitUsahaData.data.sertifikat_laik_sehat"
    ></b-form-file>

    <!-- Progress Bar -->
    <div class="progress mt-3" v-if="uploadProgress.sls" style="display: block;">
      <div
        class="progress-bar progress-bar-striped progress-bar-animated"
        role="progressbar"
        :style="{ width: uploadProgress.sls + '%' }"
      ></div>
    </div>

    <!-- File Info and Remove Button -->
    <div v-if="uploadedFiles.slsFile" class="alert alert-success mt-3">
      <button type="button" class="btn float-right" @click="removeFile('sls')">
        <i class="bi bi-trash-fill text-danger" style="font-size: 1.5rem;"></i>
      </button>
      Nama File: <span>{{ uploadedFiles.slsFile.name }}</span> <br />
      Ukuran File: <span>{{ (uploadedFiles.slsFile.size / 1024).toFixed(2) }} KB</span>
    </div>

 <!-- View Document Button -->
<div v-if="unitUsahaData.data.sls_file" class="alert alert-success mt-3">
  <a
    :href="`${url}/storage/${unitUsahaData.data.sls_file}`"
    target="_blank"
    style="text-decoration: none; color: inherit;" 
  >
    <span>Lihat Dokumen</span>
  </a>
</div>




    <div class="form-group form-check">
      <input
        v-model.trim="unitUsahaData.data.sertifikat_standar_usaha"
        type="checkbox"
        class="form-check-input"
       
        id="sertifikat_standar_usaha"
        name="sertifikat_standar_usaha"
      />
      <label class="form-check-label" for="sertifikat_standar_usaha">Sertifikat Standar Usaha (SSU)</label>
    </div>

    <div class="form-group">
      <label for="penerbit_ssu">Penerbit Sertifikat Standar Usaha (SSU):</label>
      <input
        v-model.trim="unitUsahaData.data.penerbit_ssu"
        type="text"
        class="form-control"
        id="penerbit_ssu"
        name="penerbit_ssu"
        placeholder="Masukan Penerbit Sertifikat Standar Usaha"
         :disabled="!unitUsahaData.data.sertifikat_standar_usaha"
      />
    </div>
    <div class="form-group">
   
    <label for="masa_berlaku_ssu">Masa Berlaku Sertifikat Standar Usaha (SSU):</label>
    
      <input
        v-model.trim="unitUsahaData.data.masa_berlaku_ssu"
      type="date"
        class="form-control col-sm-6"
        id="masa_berlaku_ssu"
        name="masa_berlaku_ssu"
         :disabled="!unitUsahaData.data.sertifikat_standar_usaha"
        autocomplete="off"
       
      />
    

</div>
<div class="form-group">
      <label for="ssuFile" class="form-label">Upload SSU (PDF)</label>
      <b-form-file
        ref="ssuFile"
        id="ssuFile"
        name="ssu_file"
        placeholder="Maksimal 1MB"
        accept=".pdf"
        @change="handleFileUpload('ssu', $event)"
         :disabled="!unitUsahaData.data.sertifikat_standar_usaha"
      ></b-form-file>
      <div class="progress mt-3" v-if="uploadProgress.ssu" style="display: block;">
        <div class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" :style="{ width: uploadProgress.ssu + '%' }"></div>
      </div>
      <div v-if="uploadedFiles.ssuFile" class="alert alert-success mt-3">
        <button type="button" class="btn float-right" @click="removeFile('ssu')">
          <i class="bi bi-trash-fill text-danger" style="font-size: 1.5rem;"></i>
        </button>
        Nama File: <span>{{ uploadedFiles.ssuFile.name }}</span> <br />
        Ukuran File: <span>{{ (uploadedFiles.ssuFile.size / 1024).toFixed(2) }} KB</span>
      </div>

      
 <!-- View Document Button -->
<div v-if="unitUsahaData.data.ssu_file" class="alert alert-success mt-3" >
  <a
    :href="`${url}/storage/${unitUsahaData.data.ssu_file}`"
    target="_blank"
    style="text-decoration: none; color: inherit;"
   
  >
    <span>Lihat Dokumen</span>
  </a>
</div>
    </div>
  </fieldset>


<!-- Loading spinner -->
<div v-if="loading" class="loading-overlay">
      <div class="spinner"></div>
      <p>Tunggu Sebentar ...</p>
    </div>
                <button :disabled="loading" type="submit" class="btn btn-primary">SIMPAN</button>
              </form>


             
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from 'axios';

export default {
  name: 'EditUsahaComponent',
  data() {
    return {
      loading: false,
      url: `${process.env.VUE_APP_API_BASE_URL}`,
      unitUsahaData: {
        data: {
          penanggung_jawab: [{ pengurus_id: "", kontak_pengurus: "" }],
          telpon_kantor: "",
          email: "",
          website: "",
          skala_usaha: "",
          luas_tanah: "",
          luas_bangunan: "",
          instagram: "",
          jenis_izin: '',
          nomor_izin: '',
          klasifikasi_resiko: '',
          sertifikat_laik_sehat: '',
          sertifikat_standar_usaha: '',
          penerbit_ssu: '',
          tki_pria: "",
          tki_wanita: "",
          tka_pria: "",
          tka_wanita: "",
        },
      },
      errorMessage: '',
      uploadProgress: {
        sls: 0,
        ssu: 0,
        izin: 0,
      },
      uploadedFiles: {
        slsFile: '',
        ssuFile: '',
        izinFile: '',
      },
      pengurus: [],
      isFreeTextPhone: false,
    };
  },
  async mounted() {
    await this.fetchPengurus();
    await this.fetchUnitUsahaData();
  },
  methods: {
    async fetchUnitUsahaData() {
      this.loading = true;
      try {
        const token = localStorage.getItem('token');
        const id = this.$route.query.id;

        if (!id) {
          console.error('ID is missing in the route query parameters.');
          return;
        }

        const response = await axios.get(`${this.url}/api/v1/unit-usaha/${id}`, {
          headers: {
            'Accept': 'application/json',
            'Authorization': `Bearer ${token}`,
          },
        });

        this.unitUsahaData = response.data;

        // Convert date format for the date inputs
        ['masa_berlaku_ssu', 'masa_berlaku_laik_sehat', 'tanggal_terbit_izin'].forEach(field => {
          if (this.unitUsahaData.data[field]) {
            const dateParts = this.unitUsahaData.data[field].split('/');
            if (dateParts.length === 3) {
              this.unitUsahaData.data[field] = `${dateParts[2]}-${dateParts[1]}-${dateParts[0]}`;
            }
          }
        });
        console.log('Fetched data:', this.unitUsahaData);
      } catch (error) {
        this.errorMessage = 'Error fetching unit usaha data: ' + (error.response?.data?.message || error.message);
      } finally {
        this.loading = false;
      }
    },

    async fetchPengurus() {
      this.loading = true;
      try {
        const response = await axios.get(`${this.url}/api/v1/pengurus-perusahaan`, {
          headers: {
            Accept: 'application/json',
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        });
        this.pengurus = response.data.data;
      } catch (error) {
        console.error('Error fetching pengurus:', error);
      } finally {
        this.loading = false;
      }
    },

    formatDate(date) {
      if (!date) return "";
      if (date.includes("-")) {
        const [year, month, day] = date.split("-");
        return `${day}/${month}/${year}`;
      }
      return date;
    },

    async updateUnitUsaha() {
  this.loading = true;
  const formData = new FormData();
  formData.append('_method', 'PUT');

  // Format dates for API submission
  ['tanggal_terbit_izin', 'masa_berlaku_laik_sehat', 'masa_berlaku_ssu'].forEach(field => {
    this.unitUsahaData.data[field] = this.formatDate(this.unitUsahaData.data[field]);
  });
// Loop through the fields in unitUsahaData.data
Object.keys(this.unitUsahaData.data).forEach((key) => {
  // Replace null values with empty strings
  if (this.unitUsahaData.data[key] === null) {
    this.unitUsahaData.data[key] = '';
  }

  // Append form fields, excluding 'penanggung_jawab'
  if (key !== 'penanggung_jawab') {
    formData.append(key, this.unitUsahaData.data[key]);
  }
});

  
  formData.append('penanggung_jawab[0][pengurus_id]', this.unitUsahaData.data.penanggung_jawab.pengurus_id);
  formData.append('penanggung_jawab[0][kontak_pengurus]', this.unitUsahaData.data.penanggung_jawab.kontak_pengurus);
  
  // Append files to FormData
  formData.append('sls_file', this.uploadedFiles.slsFile);
  formData.append('ssu_file', this.uploadedFiles.ssuFile);
  formData.append('file_perizinan', this.uploadedFiles.izinFile);

  try {
    const token = localStorage.getItem('token');
    const id = this.$route.query.id;

    await axios.post(`${this.url}/api/v1/unit-usaha/${id}`, formData, {
      headers: {
        'Accept': 'application/json',
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'multipart/form-data',
      },
    });

    console.log('Unit usaha updated successfully');
    this.$router.push({ path: "/dashboard/unit-usaha", query: { updated: true } });
  } catch (error) {
    this.errorMessage = 'Error updating unit usaha: ' + (error.response?.data?.message || error.message);
  } finally {
    this.loading = false;
  }
},


setPenanggungJawabData(pengurusId) {
  const selectedPengurus = this.pengurus.find(p => p.pengurus_id == pengurusId);
  if (selectedPengurus) {
    // Ensure 'penanggung_jawab' array is initialized
    if (!this.unitUsahaData.data.penanggung_jawab || !Array.isArray(this.unitUsahaData.data.penanggung_jawab)) {
      this.unitUsahaData.data.penanggung_jawab = [{}];
    }
    this.unitUsahaData.data.penanggung_jawab[0].pengurus_id = selectedPengurus.pengurus_id;
    this.unitUsahaData.data.penanggung_jawab[0].kontak_pengurus = selectedPengurus.kontak_pengurus || '';
    this.isFreeTextPhone = !selectedPengurus.kontak_pengurus;
  } else {
    console.error(`Pengurus with specified ID ${pengurusId} not found`);
  }
},


    handleFileUpload(type, event) {
      const file = event.target.files[0];
      if (file) {
        this.uploadedFiles[`${type}File`] = file;
      }
    },

    removeFile(type) {
      this.uploadedFiles[`${type}File`] = null;
      this.$refs[`${type}File`].reset();
    },
  },
};
</script>


<style scoped>
/* Add your scoped styles here if needed */
.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.8);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 9999;
}

.spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-left-color: #28a745;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

</style>

