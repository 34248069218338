<template>
    <div>
      <div class="container">
        <header class="section-header mt-5 wow zoomIn" data-wow-delay="0.1s">
          <h1><strong>Dasar Hukum</strong></h1>
          <h4>Berdasarkan Peraturan Gubernur <b>(PERGUB)</b> Provinsi Daerah Khusus Ibukota Jakarta Nomor 18 Tahun 2018</h4>
        </header>
        <b-card no-body class="mt-3">
          <b-tabs card style="box-shadow: 0 0 15px rgba(0, 0, 0, .12);">
            <b-tab title="Dasar Hukum" active>
              <b-card-text>
                <h4><strong>Laporan Kegiatan Usaha</strong></h4>
                <h5>Pasal 46</h5>
                <ol>
                  <li>Setiap pengusaha pariwisata wajib menyampaikan Laporan Kegiatan Usaha pariwisata kepada Kepala Dinas setiap 6 (enam) bulan sekali.</li>
                  <li>Laporan Kegiatan Usaha pariwisata sebagaimana dimaksud pada ayat (1) meliputi:
                    <ol type="a">
                      <li>Profil usaha:
                        <ol>
                          <li>Identitas usaha:
                            <br> identitas usaha (nama usaha, nama pemilik/penanggung jawab, nomor TD UP, bidang/jenis/subjenis usaha, klasifikasi usaha, kapasitas dan alamat lengkap lokasi usaha); dan
                          </li>
                          <li>Keterangan badan usaha:
                            <br> keterangan badan usaha (nama perusahaan, Nomor Pokok Wajib Pajak perusahaan, TDUP, dokumen pendirian dan atau perubahan, susunan pengurus, status permodalan dan alamat lengkap lokasi perusahaan).
                          </li>
                        </ol>
                      </li>
                      <li>Penyelenggaraan kegiatan usaha:
                        <ol>
                          <li>Aspek produk (sarana dan fasilitas produk yang dimiliki)</li>
                          <li>Pelayanan (sarana dan fasilitas dalam memberikan pelayanan)</li>
                          <li>Pengelolaan (organisasi, manajemen dan sumber daya manusia)</li>
                          <li>Perkembangan usaha dan strategi pengembangannya</li>
                          <li>Peran serta dalam pelaksanaan tanggung jawab sosial dan lingkungan</li>
                          <li>Kontribusi terhadap program Pemerintah Daerah</li>
                          <li>Masukan kepada Pemerintah Daerah</li>
                          <li>Pemberian data dan informasi rinci kegiatan usaha</li>
                        </ol>
                      </li>
                    </ol>
                  </li>
                  <li>Ketentuan lebih lanjut yang bersifat teknis mengenai data dan informasi rinci sebagaimana dimaksud pada ayat (2) huruf b angka 8, ditetapkan dengan Keputusan Kepala Dinas.</li>
                  <li>Kepala Dinas melakukan verifikasi terhadap Laporan Kegiatan Usaha pariwisata yang disampaikan.</li>
                  <li>Dinas memberikan pengesahan terhadap Laporan Kegiatan Usaha pariwisata yang telah sesuai dengan ketentuan peraturan perundang-undangan.</li>
                  <li>Terhadap usaha pariwisata yang menyampaikan Laporan Kegiatan Usaha pariwisata tidak benar dan/atau tidak sesuai dikenakan sanksi sesuai dengan ketentuan perundang-undangan.</li>
                </ol>
              </b-card-text>
            </b-tab>
            <b-tab title="Sanksi">
              <b-card-text>
                <h4><strong>Sanksi Administratif</strong></h4>
                <h4>Bagian Kesatu: Umum</h4>
                <h5>Pasal 52</h5>
                <ol>
                  <li>Setiap Pengusaha Pariwisata yang tidak memenuhi ketentuan sebagaimana dimaksud dalam Pasal 37 ayat (1), Pasal 38 ayat (2), Pasal 39, Pasal 43 dan Pasal 46 ayat (1) dikenai sanksi administratif.</li>
                  <li>Sanksi administratif sebagaimana dimaksud pada ayat (1) berupa:
                    <ol type="a">
                      <li>Teguran tertulis pertama;</li>
                      <li>Teguran tertulis kedua;</li>
                      <li>Teguran tertulis ketiga;</li>
                      <li>Penghentian sementara kegiatan usaha pariwisata; dan</li>
                      <li>Pencabutan TDUP disertai dengan penutupan kegiatan usaha pariwisata.</li>
                    </ol>
                  </li>
                </ol>
              </b-card-text>
            </b-tab>
            <b-tab title="Download">
              <b-card-text>
                <h5>Peraturan Gubernur (PERGUB) Provinsi Daerah Khusus Ibukota Jakarta Nomor 18 Tahun 2018</h5>
                <a href="https://peraturan.bpk.go.id/Download/78020/No_18_Tahun_2018.pdf"><button class="btn btn-primary">Download</button></a>
              </b-card-text>
            </b-tab>
          </b-tabs>
        </b-card>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: "HukumComponent"
  }
  </script>
  
  <style>

</style>
  