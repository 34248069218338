<template>
  <div>
    <nav
      id="sidebar"
      :class="{
        'sidebar': true,
        'sidebar-dark': true,
        'desktop-logo': false,
        'collapsed': isSidebarCollapsed
      }"
    >
      <!-- Sidebar - Brand -->
      <a class="sidebar-brand d-none d-lg-block d-flex align-items-center justify-content-center" :href="('/dashboard')">
        <div class="sidebar-brand-icon">
          <img 
            v-if="!isSidebarCollapsed" 
            :src="require('../../assets/images/logo-white.png')" 
            width="90%" 
            class="desktop-logo  p-4" 
          />
          <img 
            v-if="isSidebarCollapsed" 
            :src="require('../../assets/images/logoenjoy.png')" 
            width="90%" 
            class="collapsed-logo mt-3" 
          />
        </div>
      </a>
      <a class="sidebar-brand d-flex align-items-center justify-content-center" :href="('/dashboard')">
        <div class="sidebar-brand-icon mt-3">
          <img 
            :src="require('../../assets/images/logoenjoy.png')" 
            width="100%" 
            class="d-md-none" 
          />
        </div>
      </a>

      <!-- Sidebar Divider (outside of ul) -->
      <hr class="sidebar-divider thin-hr" /> 

      <!-- Nav Items -->
      <ul class="navbar-nav p-4">
        <!-- Nav Item - Dashboard -->
        <li :class="['nav-item', isActiveRoute('dashboard') ? 'active' : '']">
          <router-link class="nav-link" to="/dashboard" exact>
            <i class="bi bi-speedometer2"></i>
            <span>Dashboard</span>
          </router-link>
        </li>

        <!-- Sidebar Divider (inside ul) -->
        <li><hr class="sidebar-divider thin-hr"></li>

        <!-- Nav Item - Data Perusahaan -->
        <li :class="['nav-item', isActiveRoute('dashboard/perusahaan') ? 'active' : '']">
          <router-link class="nav-link" to="/dashboard/perusahaan" >
          
            
            <i class="bi bi-bank"></i>
            <span>Data Perusahaan</span>
          </router-link>
        </li>
        <li><hr class="sidebar-divider thin-hr"></li>
        
        <!-- Nav Item - Data Unit Usaha -->
        <li :class="['nav-item', isActiveRoute('unit-usaha') ? 'active' : '']">
          <router-link class="nav-link" :to="unitUsahaLink">
            <i class="bi bi-shop-window"></i>
            <span>Data Unit Usaha</span>
          </router-link>
        </li>

        <!-- Sidebar Divider (inside ul) -->
        <li><hr class="sidebar-divider thin-hr"></li>
        
        <!-- Logout -->
        <li :class="['nav-item', isActiveRoute('logout') ? 'active' : '']">
  <a href="/logout" class="nav-link" @click.prevent="logout">
    <i class="bi bi-box-arrow-in-left"></i>
    <span>Logout</span>
  </a>
</li>

      </ul>

      <!-- Sidebar Divider (outside of ul) -->
      <hr class="sidebar-divider thin-hr" /> 

      <!-- Sidebar Toggler -->
      <div class="text-center d-none d-lg-block">
        <button class="btn btn-dark-circle" @click="toggleSidebar">
          <i class="bi" :class="sidebarTogglerIcon"></i>
        </button>
      </div>
    </nav>

    <!-- Content Wrapper -->
    <div id="content-wrapper">
      <div id="content">
        <!-- Main Content -->
        <router-view />
      </div>
    </div>
    
<!-- Loading spinner -->
<div v-if="loading" class="loading-overlay">
      <div class="spinner"></div>
      <p>Tunggu Sebentar ...</p>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: "SidebarComponent",
  data() {
    return {
      loading: false, // Add this state
      isSidebarCollapsed: true
    };
  },
  methods: {
    isActiveRoute(route) {
      // Match route exactly
      return this.$route.path === route;
    },
    getUrl(path) {
      return process.env.BASE_URL + path;
    },
    async logout() {
      this.loading = true; // Set loading to true when submission starts
    
      try {
        // Send a logout request
        const response = await axios({
          method: 'get', // Use the correct method if needed
          url: `${process.env.VUE_APP_API_BASE_URL}/api/logout`,
          headers: {
            'Accept': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
          },
        });

        if (response.status === 200) {
          // Logout successful
          localStorage.removeItem('token');
          this.$router.push('/login'); // Redirect to login page
        } else {
          console.error('Logout failed:', response);
        }
      } catch (error) {
        console.error('Error during logout:', error);
      }finally {
    this.loading = false; // Reset loading state after submission completes
  }

    },
    toggleSidebar() {
      const sidebar = document.getElementById('sidebar');
      sidebar.classList.toggle('collapsed');
      this.isSidebarCollapsed = !this.isSidebarCollapsed;
    },
    checkScreenSize() {
      this.isSidebarCollapsed = window.innerWidth < 992;
    }
  },
  mounted() {
    this.checkScreenSize();
    window.addEventListener('resize', this.checkScreenSize);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.checkScreenSize);
  },
  computed: {
    unitUsahaLink() {
      return "/dashboard/unit-usaha";
    },
    sidebarTogglerIcon() {
      return this.isSidebarCollapsed 
        ? 'bi-arrow-right-circle-fill icon-lg text-white' 
        : 'bi-arrow-left-circle-fill icon-lg text-white';
    }
  }
};
</script>

<style scoped>
/* Sidebar Styling */
#sidebar {
  background: linear-gradient(to bottom, #05274B, #05274B 80%, #1616a6);
  height: 100vh; /* Full viewport height */
  position: fixed;
  top: 0;
  left: 0;
  width: 250px;
  z-index: 1000;
  overflow-y: visible;
  transition: all 0.3s;
  padding-bottom: 0; /* Ensure no extra padding at the bottom */
  box-sizing: border-box; /* Ensure padding and border are included in the height */
}

/* Add your scoped styles here if needed */
.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.8);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 9999;
}

.spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-left-color: #28a745;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.icon-lg {
  font-size: 44px; /* Default icon size */
}

#sidebar.collapsed {
  width: 80px;
}

#sidebar.collapsed .nav-link i {
  font-size: 2rem; /* Increase icon size when collapsed */
  margin-right: 0;
  text-align: center;
  width: 100%;
}

#sidebar.collapsed .nav-link span {
  display: none; /* Hide text labels when collapsed */
}

#sidebar.collapsed .nav-item {
  justify-content: center;
}

#sidebar.collapsed .sidebar-brand {
  font-size: 0; /* Hide the brand text when collapsed */
}

#sidebar.collapsed .sidebar-brand-icon img {
  max-width: 50px; /* Resize the logo when collapsed */
}

#sidebar.collapsed .sidebar-divider {
  display: none; /* Hide dividers when collapsed */
}

#content-wrapper {
  margin-left: 250px;
  width: calc(100% - 250px);
  transition: margin-left 0.3s;
}

#sidebar.collapsed + #content-wrapper {
  margin-left: 80px;
  width: calc(100% - 80px);
}

.sidebar-brand {
  color: #fff;
  font-weight: bold;
  font-size: 1.2rem;
}

.sidebar-brand-icon img {
  max-width: 90%;
}

.nav-link {
  color: #bfbfbf;
  font-weight: 500;
  display: flex;
  align-items: center;
  transition: color 0.3s ease-in-out;
}

.nav-link:hover {
  color: #e9ecef;
}

.nav-link i {
  font-size: 1.2rem;
  margin-right: 10px;
}

.nav-link::before {
  content: none;
}
.router-link-active{
  font-weight: bold; /* Make the font bold for the active link */
  font-size: medium;
  color: #fff; /* Adjust color if needed for the active link */
}


.sidebar-divider {
  border-color: rgba(255, 255, 255, 0.15);
}

.sidebar-heading {
  font-size: 0.85rem;
  text-transform: uppercase;
  color: rgba(255, 255, 255, 0.6);
  padding: 0 1.5rem;
  margin-top: 1rem;
}

.text-center button {
  margin-top: 20px;
  font-size: 1.2rem;
}

/* Responsive Design */
@media (max-width: 991px) {
  #sidebar {
    position: fixed;
    top: 0;
    width: 100%;
    height: 100vh;
    overflow-y: visible;
    padding-bottom: 0; /* Ensure no extra padding at the bottom */
    will-change: transform;
    box-sizing: border-box; /* Ensure padding and border are included in the height */
  }

  #content-wrapper {
    margin-left: 0;
    width: 100%;
  }
}

.collapsed-logo {
  display: none;
}

#sidebar.collapsed .collapsed-logo {
  display: block;
}

#sidebar.collapsed .desktop-logo {
  display: none;
}

@media (max-width: 991px) {
  .desktop-logo {
    display: none;
  }
  
  .collapsed-logo {
    display: block;
  }

  #sidebar.collapsed .collapsed-logo {
    display: none;
  }

  #sidebar.collapsed .desktop-logo {
    display: none;
  }
}
</style>
